import React, { useEffect } from "react";
import {
  Modal,
  Box,
  Typography,
  Stack,
  Stepper,
  Step,
  StepLabel,
} from "@mui/material";
import OrderProceed from "../../../assets/svg/OrderProceed";
import OrderApproved from "../../../assets/svg/OrderApproved";
import TransactionStarted from "../../../assets/svg/TransactionStarted";
import Mining from "../../../assets/svg/Mining";
import { FcApproval } from "react-icons/fc";
import theme from "../../../theme/Theme";

const StepsModel = ({
  drawDetails,
  currentStep,
  timer,
  onSuccess,
  open,
  onClose,
}) => {
  const stepperSteps = [
    "Approval Request",
    "Confirming Approval",
    "Payment Confirmation",
    "NFT Minting",
  ];
  const stepperStepsDescription = [
    "Please approve SwssDraw to use your USDT for this transaction.",
    "Registering your entry on the blockchain. This may take a few moments.",
    "Please confirm the ticket purchase in your MetaMask. Once approved, the transaction will be processed.",
    "Payment confirmed! Your NFT ticket is being issued. ",
  ];

  const handleClose = (event, reason) => {
    if (reason === "backdropClick") {
      return; // Prevent closing on outside click
    }
    onClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={styles.modalStyle}>
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography sx={styles.modalTitle}>
            {/* Order <span style={styles.orderNumber}>#095884</span> */}
            Transaction in progress...
          </Typography>
          <Stack direction={"column"} alignItems={"end"} gap={0.2}>
            <Typography sx={styles.drawDetail}>
              Draw ID <span style={styles.orderNumber}>{drawDetails.id}</span>
            </Typography>
            <Typography sx={styles.drawDetail}>{drawDetails.title}</Typography>
          </Stack>
        </Stack>
        {currentStep === 4 ? (
          <Stack direction={"column"} alignItems={"center"} mt={2}>
            <FcApproval size={50} />
            <Typography sx={{ fontSize: "1rem", fontWeight: 500 }} mt={3}>
              Thank you for your purchase.
              <br />
              Now you will be redirected to My Tickets page..
            </Typography>
          </Stack>
        ) : (
          <>
            <Stack spacing={2} sx={{ mt: 3, mb: 3, width: "100%" }}>
              <Stepper
                activeStep={currentStep + 1}
                sx={{
                  "& .MuiStepConnector-line": {
                    borderColor: theme.palette.primary.main,
                  },
                }}
              >
                {stepperSteps.map((label) => (
                  <Step key={label}>
                    <StepLabel
                      slotProps={{
                        label: { sx: { fontSize: "0.8rem", fontWeight: 600 } },
                      }}
                    ></StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Stack>
            <Stack
              direction={"row"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={2}
              mt={1}
              mb={3}
            >
              <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                <OrderProceed width={24} height={24} />
                <Typography sx={styles.stepperLabelText}>
                  Approval
                  <br />
                  Request
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={0.3}>
                <OrderApproved width={24} height={24} />
                <Typography sx={styles.stepperLabelText}>
                  Confirming
                  <br />
                  Approval
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={0.2}>
                <TransactionStarted width={24} height={24} />
                <Typography sx={styles.stepperLabelText}>
                  Payment
                  <br />
                  Confirmation
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={0.2}>
                <Mining width={24} height={24} />
                <Typography sx={styles.stepperLabelText}>
                  NFT
                  <br />
                  Minting
                </Typography>
              </Stack>
            </Stack>
            <Typography sx={styles.stepText}>
              {`${stepperSteps[currentStep]}: `}
              <span style={styles.stepDescriptionText}>
                {stepperStepsDescription[currentStep]}
              </span>
            </Typography>
            {/* Timer */}
            {timer > 0 ? (
              <Typography sx={styles.timerText}>
                <span style={styles.timerBold}>{timer}</span> seconds
                remaining...
              </Typography>
            ) : (
              <Typography sx={styles.timerText}>
                Your transaction is being processed. Just a moment longer!
              </Typography>
            )}
          </>
        )}
      </Box>
    </Modal>
  );
};

export default StepsModel;

const styles = {
  modalStyle: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { xs: "96%", sm: 600 },
    bgcolor: "background.paper",
    borderRadius: "15px",
    p: { xs: 1, lg: 4 },
    textAlign: "center",
  },
  stepperLabelText: {
    fontSize: "0.7rem",
    fontWeight: 500,
    textAlign: "left",
  },
  closeButton: {
    position: "absolute",
    right: 8,
    top: 8,
  },
  drawDetail: {
    fontWeight: 500,
    fontSize: { xs: "12px", sm: "12px", md: "12px" },
    textAlign: "right",
    textTransform: "uppercase",
  },
  modalTitle: {
    fontWeight: 500,
    fontSize: { xs: "18px", sm: "18px", md: "18px" },
    textAlign: "center",
  },
  orderNumber: {
    fontWeight: 700,
    fontSize: { xs: "18px", sm: "18px", md: "18px" },
    textAlign: "center",
    color: theme.palette.primary.main,
  },
  progressBar: {
    height: 10,
    borderRadius: 5,
    backgroundColor: "#f0f0f0",
    "& .MuiLinearProgress-bar": {
      borderRadius: 5,
      backgroundColor: "#7e0e17",
    },
  },
  progressText: {
    fontSize: "16px",
    fontWeight: "500",
    color: "#333",
    textAlign: "center",
  },
  loaderContainer: {
    mt: 4,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  timerText: {
    mt: 2,
    color: "gray",
    fontSize: "14px",
  },
  timerBold: {
    fontWeight: "bold",
    fontSize: "20px",
    color: theme.palette.primary.main,
  },
  stepText: {
    fontSize: "0.8rem",
    fontWeight: 600,
    color: "#2f2f2f",
  },
  stepDescriptionText: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "#3e3e3e",
  },
};
