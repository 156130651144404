import { alpha } from "@mui/material";
import {
  CarouselBg,
  CarouselWithoutCharacter,
  HappyCustomerBg,
  HeroBg,
  ReferralInfo,
  TableBg,
} from "../../assets";
import theme from "../../theme/Theme";

export const styles = {
  carouselStyle: {
    background: `url(${HeroBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
  },
  carouselBg: {
    background: `url(${CarouselBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: {
      xs: "29em",
      sm: "28em",
      md: "calc(100vh - 56px)",
      lg: "calc(100vh - 56px)",
      xl: "calc(100vh - 56px)",
    },
    padding: { xs: 5, sm: 5, md: 10, lg: 10 },
  },
  carouselWithoutCharacterBg: {
    background: `url(${CarouselWithoutCharacter})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: {
      xs: "29em",
      sm: "28em",
      md: "calc(100vh - 56px)",
      lg: "calc(100vh - 56px)",
      xl: "calc(100vh - 56px)",
    },
    padding: { xs: 4, sm: 5, md: 10, lg: 10 },
  },
  flexContainer: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-between",
    alignItems: "center",
    gap: 4,
  },
  carouselDrawCard: {
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    flex: 1,
    borderRadius: "0.4em",
    overflow: "hidden",
    width: "100%",
    height: "100%",
    background: `linear-gradient(180deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 100%)`,
    p: 1,
  },
  carouselDrawCardImage: {
    borderRadius: "0.4em",
    width: "80%",
    aspectRatio: "16/9",
    margin: "0 auto",
  },
  carouselCardContent: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",

    borderRadius: "0.4em",
    backdropFilter: "blur(4px)",
    padding: "0.3em",
  },
  buyNowButton: {
    background: theme.custom.white,
    color: theme.palette.primary.main,
    fontSize: "clamp(0.8rem, 2.5vw, 1rem)",
    fontWeight: "600",
    borderRadius: "0.4em",
  },
  carouselBannerImage: {
    objectFit: "contain",
    backgroundColor: "#000000",
    width: "5em",
    height: "5em",
    border: "2px solid rgba(255, 255, 255, 1)",
    borderRadius: "50%",
    zIndex: 1,
  },
  carouselTextStyle: {
    color: theme.custom.white,
    fontSize: "clamp(0.8rem, 2.5vw, 1rem)",
    fontWeight: "600",
  },
  carouselWinProbTextStyle: {
    color: theme.custom.white,
    fontSize: "clamp(0.8rem, 2.5vw, 0.8rem)",
    fontWeight: "600",
    lineHeight: 1,
    textAlign: "center",
    marginBottom: "0.3em",
  },
  carouselWinProbValueStyle: {
    color: theme.custom.white,
    fontSize: "clamp(2rem, 5vw, 2.6rem)",
    fontWeight: "600",
    lineHeight: 1,
  },
  carouselTotalProbValueStyle: {
    color: theme.custom.white,
    fontSize: "clamp(1rem, 5vw, 1.2rem)",
    fontWeight: "700",
    lineHeight: 1,
    textAlign: "center",
  },
  carouselTotalWinProbValueStyle: {
    color: theme.custom.white,
    fontSize: "clamp(1rem, 5vw, 2rem)",
    fontWeight: "700",
    lineHeight: 1,
    textAlign: "center",
  },
  carouselDescription: {
    marginLeft: {
      xs: "0em",
      sm: "0em",
      md: "0em",
      lg: "2.5em",
      xl: "2.5em",
    },
  },
  winnerBoxStyle: {
    borderRadius: "0.4em",
    padding: "0.2em 0.4em",
  },
  winnerBoxTitleStyle: {
    fontSize: "clamp(0.6rem, 3vw, 0.6rem)",
    fontWeight: "600",
    color: theme.custom.white,
  },
  winnerBoxTitleValueStyle: {
    fontSize: "clamp(0.8rem, 2.5vw, 1rem)",
    fontWeight: "600",
    color: theme.custom.white,
  },
  heading1: {
    fontSize: "clamp(1rem, 2.5vw, 2rem)",
    fontWeight: "600",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    padding: "0",
    marginTop: {
      xs: "0em",
      sm: "0em",
      md: "2em",
      lg: "2em",
      xl: "2.5em",
    },
  },
  heading2: {
    fontSize: "clamp(2rem, 4vw, 4rem)",
    fontWeight: "600",
    color: theme.custom.black,
    textTransform: "uppercase",
    lineHeight: 1,
    marginTop: {
      xs: "0em",
      sm: "0em",
      md: "0em",
      lg: "0.2em",
      xl: "0.2em",
    },
  },
  heading3: {
    fontSize: "clamp(2rem, 4vw, 4rem)",
    fontWeight: "800",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
    lineHeight: 1,
  },
  description: {
    color: theme.custom.emperor,
    fontSize: "clamp(0.9rem, 2.8vw, 1.2rem)",
    fontWeight: "500",
    marginTop: "1em",
    textWrap: "wrap",
    maxWidth: "55%",
  },
  buttonWrapper: {
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "12px",
    padding: { xs: "0.15em", sm: "0.15em", md: "0.15em", lg: "0.15em" },
    width: "fit-content",
    marginTop: {
      xs: "0.4em",
      sm: "1em",
      md: "1.5em",
      lg: "1.5em",
      xl: "1.5em",
    },
    marginLeft: {
      xs: "0em",
      sm: "0em",
      md: "0em",
      lg: "2.5em",
      xl: "2.5em",
    },
    marginBottom: "1em",
    "&:hover": {
      cursor: "pointer",
    },
  },
  buttonContainer: {
    background: `linear-gradient(to right, ${theme.palette.primary.main}, ${theme.palette.primary.dark})`,
    padding: { xs: "0.2em 0.8em", lg: "0.4em 0.8em" },
    borderRadius: "10px",
  },
  buttonText: {
    color: theme.custom.white,
    fontSize: "clamp(0.8rem, 3vw, 1rem)",
    fontWeight: "600",
    textTransform: "uppercase",
    textAlign: "center",
  },
  howItWorks: {
    position: "relative",
    minHeight: "100vh",
    top: "-3.9em",
  },
  howItWorksMobile: {
    position: "relative",
    top: "-2em",
    minHeight: "100vh",
  },
  titleText: {
    position: "relative",
    fontWeight: "800",
    margin: "1em 2em 0.3em 1em",
    paddingTop: { xs: "1em", lg: "0.3em" },
    fontSize: { xs: "5.5vw", lg: "3.2vw" },
    textAlign: "center",
    color: theme.custom.black,
    textTransform: "uppercase",
    zIndex: 99,
  },
  titleHighLight: {
    color: theme.palette.primary.main,
    borderBottom: "1.5px solid rgba(0, 0, 0, 1)",
  },
  subTitleText: {
    fontSize: { xs: "3.5vw", lg: "1vw" },
    color: "rgba(0, 0, 0, 0.8)",
    fontWeight: "600",
    textAlign: "center",
    width: { xs: "90%", md: "70%", lg: "60%" },
    margin: "0 auto",
  },
  happyCustomerSubTitle: {
    fontSize: "1.2rem",
    color: "rgba(255, 255, 255, 0.8)",
    fontWeight: "600",
  },
  cardPalette: {
    position: "relative",
    zIndex: 99,
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "#FFFFFF",
    padding: {
      xs: "0.6em 0.8em",
      sm: "1em 2em",
      md: "0.8em 1em",
      lg: "0.8em 1em",
    },
    borderRadius: "1em",
    maxWidth: { xs: "100%", sm: "100%", md: "100%", lg: "90%" },
  },
  cardImage: {
    maxWidth: "2.6em",
    height: "auto",
  },
  cardTitle: {
    fontSize: { xs: "4vw", lg: "1.5vw" },
    fontWeight: "700",
    color: theme.palette.primary.main,
    textTransform: "uppercase",
  },
  cardTitleNo: {
    color: "rgba(213, 213, 213, 1)",
  },
  cardDescription: {
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: "600",
    color: "rgba(82, 82, 82, 1)",
    lineHeight: 1.2,
    marginTop: "5px",
  },
  winnerBg: {
    position: "absolute",
    width: "300px",
    bottom: "20%",
    left: 0,
    right: 0,
    margin: "0 auto",
    zIndex: -1,
  },
  activeCompetitions: {
    position: "relative",
    background: `linear-gradient(325deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 87%, ${theme.custom.primaryDarker} 100%)`,
    width: "100vw",
    top: 0,
    zIndex: 2,
    paddingBottom: 50,
  },
  activeCompetitionBg: {
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    width: "calc(100% - 10px)",
    margin: "0 auto",
    zIndex: -1,
  },
  competitionCard: {
    backgroundColor: "#FFFFFF",
    borderRadius: "1em",
    margin: { sx: 1, md: 2, lg: 3 },
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: 240,
  },
  titleContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "0.5em 1em",
    zIndex: 3,
  },
  competitionCardImage: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: 240,
    width: "100%",
    objectFit: "fill",
    borderRadius: "1em 1em 0 0",
    zIndex: 2,
  },
  competitionCardTitle: {
    fontSize: { xs: "4.2vw", lg: "1.8vw" },
    fontWeight: "800",
    color: "rgba(255, 255, 255, 1)",
    textTransform: "uppercase",
    textAlign: "center",
  },
  totalPoolPriceText: {
    fontSize: { xs: "3.2vw", lg: "1.25vw" },
    fontWeight: 500,
    color: "rgba(51, 51, 51, 1)",
    display: "block",
    marginLeft: 2,
  },
  totalPoolPriceTicker: {
    fontWeight: 600,
    fontSize: { xs: "2.5vw", lg: "1vw" },
    color: theme.palette.primary.main,
    marginLeft: 2,
  },

  totalPoolPriceValueText: {
    fontWeight: 600,
    fontSize: { xs: "3.3vw", lg: "1.28vw" },
    color: theme.palette.primary.main,
  },
  otherTitleText: {
    fontSize: { xs: "3.1vw", lg: "1vw" },
    fontWeight: 600,
    color: "#706F6F",
    lineHeight: 1.2,
  },
  blockchainIcon: {
    margin: "0 0.5em",
  },
  otherPriceValueText: {
    fontWeight: 600,
    fontSize: { xs: "3.2vw", lg: "1.2vw" },
    color: "#3F3F42",
    lineHeight: 1.2,
  },
  progressBarContainer: {
    position: "relative",
    height: "10px",
    width: "100%",
    borderRadius: "10em",
    overflow: "hidden",
    backgroundColor: "#DADADA",
  },
  soldTickers: {
    backgroundColor: "#0093E9",
    backgroundImage:
      "linear-gradient(90deg, #2AF598 0%, #eae608 31%, #fb9b03 65%, #ff0004 100%)",
    height: "10px",
    borderRadius: "10em",
  },
  progressText: {
    fontSize: { xs: "2vw", lg: "0.9vw" },
    fontWeight: 600,
    color: "#3E3E3E",
    lineHeight: 1.2,
  },
  competitionCardDescription: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "rgba(82, 82, 82, 1)",
  },
  competitionCardButton: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  competitionCardContent: {
    paddingTop: "0.5em",
  },
  bookmarkIcon: {
    border: "1.4px solid rgba(191, 21, 34, 1)",
    borderRadius: "50%",
    padding: "0.2em",
    width: "1.8em",
    height: "1.8em",
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  daysText: {
    color: "#3F3F42",
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: 600,
  },
  pricePerTicket: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "1em",
  },
  joinButtonText: {
    backgroundColor: theme.palette.primary.main,
    padding: "0.5em 1.4em",
    borderBottomRightRadius: "1em",
    borderTopLeftRadius: "1em",
    color: "white",
    fontSize: { xs: "3.1vw", lg: "1.1vw" },
    fontWeight: "500",
  },
  viewMoreButton: {
    backgroundColor: "#FFFFFF",
    color: theme.palette.primary.main,
    fontSize: { xs: "3vw", lg: "1.2vw" },
    fontWeight: 600,
    textTransform: "uppercase",
    marginTop: "2em",
    "&:hover": {
      cursor: "pointer",
    },
  },
  happyCustomerTitle: {
    fontWeight: "800",
    fontSize: { xs: "5.5vw", lg: "3.2vw" },
    color: "rgba(0, 0, 0, 1)",
    textTransform: "uppercase",
    textAlign: "left",
  },
  happyCustomerHighLight: {
    color: "rgba(255, 255, 255, 1)",
    borderBottom: "1.5px solid rgba(0, 0, 0, 1)",
  },
  happyCustomerSection: {
    background: `url(${HappyCustomerBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 50%",
    backgroundPosition: "center",
    position: "absolute",
    width: "100vw",
    padding: "4em",
    left: 0,
    right: 0,
    zIndex: -1,
  },
  customerCardHolder: {
    display: "flex",
    flexDirection: {
      xs: "column",
      sm: "column",
      md: "row",
      lg: "row",
      xl: "row",
    },
    justifyContent: "space-between",
    alignItems: "center",
    gap: 1,
    margin: { lg: "0 3em" },
  },
  happyCustomerCard: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: "#FFFFFF",
    borderRadius: "1em",
    margin: { xs: "1em", sm: "1em", md: "2em", lg: "2em" },
    boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
    transition: "all 0.3s ease",
  },
  customerName: {
    fontSize: { xs: "3.8vw", lg: "1.6vw" },
    fontWeight: 700,
    textAlign: "center",
    textTransform: "uppercase",
    color: "rgba(0, 0, 0, 1)",
  },
  customerDesignation: {
    fontSize: { xs: "3.2vw", lg: "1.2vw" },
    fontWeight: 700,
    textAlign: "center",
    marginTop: "0.5em",
    textTransform: "uppercase",
    color: "rgba(82, 82, 82, 1)",
  },
  customerDescription: {
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: 500,
    textAlign: "center",
    color: "rgba(137, 137, 137, 1)",
    marginTop: "1em",
  },
  viewMoreRedButton: {
    backgroundColor: "rgba(255, 255, 255, 1)",
    color: theme.palette.primary.main,
    fontSize: "0.8rem",
    fontWeight: "600",
    textTransform: "uppercase",
    marginTop: "2em",
    marginBottom: "1em",
    marginRight: "2em",
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  celebrationBg: {
    width: "100vw",
  },
  celebrationImage1: {
    position: "absolute",
    right: 20,
    top: 0,
    width: "4em",
    zIndex: -1,
  },
  celebrationImage2: {
    position: "absolute",
    left: 30,
    top: 5,
    width: "2em",
    zIndex: -1,
  },
  celebrationImage3: {
    position: "absolute",
    left: 90,
    top: 80,
    width: "4em",
    zIndex: -1,
  },
  celebrationImage4: {
    position: "absolute",
    left: 15,
    bottom: 10,
    transform: "rotate(-40deg)",
    width: "4em",
    zIndex: -1,
  },
  celebrationImage5: {
    position: "absolute",
    left: "30%",
    top: 50,
    transform: "rotate(30deg)",
    width: "4em",
    zIndex: -1,
  },
  winnerTickerNo: {
    backgroundColor: "#ffffff",
    border: "1px solid rgba(255, 143, 152, 1)",
    height: "1.6em",
    width: "1.6em",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "rgba(82, 82, 82, 1)",
    fontSize: "0.8rem",
    fontWeight: 600,
    padding: "0.2em",
  },
  tableHeadCell: {
    fontSize: { xs: "0.8rem", lg: "0.8rem" },
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center",
  },
  referralBox: {
    position: "relative",
    zIndex: 1,
    paddingBottom: { xs: "1em", md: "6em", lg: "6em" },
    paddingTop: "0.5em",
    overflow: "hidden",
  },
  referralBg: {
    position: "absolute",
    clipPath:
      "polygon(100% 100%, 0% 100% , 0.00% 1.41%, 1.11% 1.33%, 2.22% 1.25%, 3.33% 1.19%, 4.44% 1.13%, 5.56% 1.08%, 6.67% 1.03%, 7.78% 1.00%, 8.89% 0.97%, 10.00% 0.96%, 11.11% 0.95%, 12.22% 0.95%, 13.33% 0.96%, 14.44% 0.98%, 15.56% 1.00%, 16.67% 1.04%, 17.78% 1.08%, 18.89% 1.14%, 20.00% 1.20%, 21.11% 1.27%, 22.22% 1.34%, 23.33% 1.43%, 24.44% 1.52%, 25.56% 1.62%, 26.67% 1.73%, 27.78% 1.85%, 28.89% 1.97%, 30.00% 2.10%, 31.11% 2.24%, 32.22% 2.38%, 33.33% 2.53%, 34.44% 2.69%, 35.56% 2.85%, 36.67% 3.01%, 37.78% 3.18%, 38.89% 3.36%, 40.00% 3.54%, 41.11% 3.73%, 42.22% 3.92%, 43.33% 4.11%, 44.44% 4.30%, 45.56% 4.50%, 46.67% 4.70%, 47.78% 4.91%, 48.89% 5.11%, 50.00% 5.32%, 51.11% 5.53%, 52.22% 5.73%, 53.33% 5.94%, 54.44% 6.15%, 55.56% 6.36%, 56.67% 6.57%, 57.78% 6.77%, 58.89% 6.98%, 60.00% 7.18%, 61.11% 7.38%, 62.22% 7.58%, 63.33% 7.77%, 64.44% 7.96%, 65.56% 8.15%, 66.67% 8.34%, 67.78% 8.52%, 68.89% 8.69%, 70.00% 8.86%, 71.11% 9.03%, 72.22% 9.19%, 73.33% 9.34%, 74.44% 9.49%, 75.56% 9.63%, 76.67% 9.77%, 77.78% 9.90%, 78.89% 10.02%, 80.00% 10.14%, 81.11% 10.24%, 82.22% 10.34%, 83.33% 10.43%, 84.44% 10.52%, 85.56% 10.59%, 86.67% 10.66%, 87.78% 10.72%, 88.89% 10.77%, 90.00% 10.82%, 91.11% 10.85%, 92.22% 10.87%, 93.33% 10.89%, 94.44% 10.90%, 95.56% 10.90%, 96.67% 10.89%, 97.78% 10.87%, 98.89% 10.84%, 100.00% 10.81%)",
    background: `linear-gradient(325deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 87%, ${theme.custom.primaryDarker} 100%)`,
    top: 0,
    left: 0,
    right: 0,
    bottom: "-20em",
    zIndex: -1,
  },
  referralBoxInfo: {
    background: `url(${ReferralInfo})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: { xs: "90% 85%", sm: "cover", md: "100% 100%" },
    backgroundPosition: "center",
    margin: "0 auto",
    padding: "0.5em",
    marginBottom: { xs: "1em", sm: "1em", md: "14em", lg: "14em" },
    marginTop: { xs: "1em" },
    width: {
      xs: "100%",
      sm: "100%",
      md: "60%",
      lg: "60%",
      xl: "60%",
    },
  },
  referralBoxTitle: {
    fontWeight: 500,
    fontSize: { xs: "5.2vw", lg: "2.4vw" },
    color: "rgba(255, 255, 255, 1)",
    textTransform: "uppercase",
    zIndex: 1,
    paddingTop: "1em",
    paddingLeft: { xs: "1em", lg: "0.5em" },
  },
  referralBoxTitleBold: {
    fontWeight: 700,
  },
  referralBoxSubTitle: {
    fontWeight: 700,
    fontSize: { xs: "5.4vw", lg: "2.6vw" },
    color: "rgba(255, 255, 255, 1)",
    textTransform: "uppercase",
    paddingLeft: { xs: "1em", lg: "0.5em" },
  },
  faqTitle: {
    fontSize: { xs: "4vw", lg: "2vw" },
    fontWeight: 800,
    color: "rgba(255,255,255,1)",
    paddingTop: { xs: "1em" },
  },
  faqDesc: {
    fontSize: { xs: "2.5vw", lg: "1vw" },
    fontWeight: 500,
    color: "rgba(255,255,255,1)",
    marginBottom: "1em",
  },
  faqBox: {
    background:
      "95px 95px/calc(2*95px) calc(2*95px) conic-gradient(at calc(500%/6) 50%,#ffffff 25%,#0000 0),0 0/calc(2*95px) calc(2*95px) conic-gradient(at calc(500%/6) 50%,#ffffff 25%,#0000 0),32px 32px/calc(2*95px) calc(2*95px) conic-gradient(at calc(200%/3) 50%,#fbfbfb 25%,#0000 0),0 0/calc(2*32px) calc(2*32px) conic-gradient(at calc(200%/3) 50%,#fbfbfb 25%,#0000 0),repeating-conic-gradient(#f7f7f7 0 25%,#0000 0 50%) 0 0/calc(2*32px) calc(2*32px),linear-gradient(#f7f7f7 calc(100%/3),#fbfbfb 0 calc(200%/3),#ffffff 0) 0 0/32px 32px",
    width: "100%",
    height: { xs: "40vh", lg: "60vh" },
    borderRadius: "1em",
    overflow: "scroll",
  },
  faqBoxTitle: {
    fontSize: { xs: "3.1vw", lg: "1.2vw" },
    fontWeight: 600,
    color: "rgba(0,0,0,1)",
  },
  faqBoxDesc: {
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: 500,
    color: "rgba(0,0,0,1)",
  },
  tableBodyCell: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(0,0,0,1)",
    textAlign: "center",
  },
  linkStyle: {
    color: theme.custom.link,
    fontSize: "0.8rem",
    fontWeight: 500,
    textDecoration: "none",
  },
  tableContainerStyle: {
    margin: "2em auto",
    width: "100%",
    borderRadius: "10px",
    backgroundImage: `url(${TableBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "110% 110%",
    backgroundPosition: "center",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  topRightBg: {
    position: "absolute",
    top: 0,
    left: 0,
  },
  bottomRightBg: {
    position: "absolute",
    right: 0,
    bottom: 0,
  },
  smartChainTitleText: {
    fontSize: "clamp(1.5rem, 2.5vw, 2rem)",
    color: "#3F3F42",
    fontWeight: 700,
    textAlign: "center",
  },
  alightRight: {},
  viewMoreButtonText: {
    color: theme.palette.primary.main,
    fontSize: { xs: "2.8vw", lg: "1vw" },
    fontWeight: 600,
    textTransform: "uppercase",
  },
};
