import { ethers } from "ethers";
import swssDrawABI from "../abi/swssDraw";
import Cookies from "js-cookie";
import { contractAddress, fromBlock } from "../config";
import Web3 from "web3";
import { blockchainNetworks } from "../config/blockchain";

// Utility function to filter unique referrals based on the referee's address
const getUniqueReferrals = (referralTree) => {
  const uniqueReferrals = [];
  const seenAddresses = new Set();

  referralTree.forEach((referral) => {
    if (!seenAddresses.has(referral.referee.toLowerCase())) {
      uniqueReferrals.push(referral);
      seenAddresses.add(referral.referee.toLowerCase());
    }
  });

  return uniqueReferrals;
};

// Utility function for adding delay (to handle rate limiting)
const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// Fetch referrals recursively with multiple blockchain support
export const fetchReferrals = async (referrer, maxDepth = 10) => {
  let web3;
  let previousBlockchain = null;
  const referralTree = [];

  try {
    for (const blockchainKey in blockchainNetworks) {
      const blockchainData = blockchainNetworks[blockchainKey];
      if (blockchainKey === "swssMainnet") continue; // need to remove
      // Initialize Web3 provider only if the blockchain changes
      if (previousBlockchain !== blockchainKey) {
        web3 = new Web3(
          new Web3.providers.HttpProvider(blockchainData.infuraUrl)
        );
        previousBlockchain = blockchainKey;
      }

      const contract = new web3.eth.Contract(swssDrawABI, contractAddress);

      // Fetch past ReferralSet events
      const events = await contract.getPastEvents("ReferralSet", {
        fromBlock: fromBlock,
        toBlock: "latest",
      });

      // Add delay between requests to avoid rate-limiting issues
      await delay(500); // Adjust the delay as needed

      // Process the referral tree recursively
      const processReferral = (currentReferrer, depth) => {
        if (depth > maxDepth) return;

        // Filter events where the currentReferrer is the referrer
        const directReferrals = events
          .filter(
            (event) =>
              event.returnValues.referrer.toLowerCase() ===
              currentReferrer.toLowerCase()
          )
          .map((event) => event.returnValues.referee);

        directReferrals.forEach((referee) => {
          referralTree.push({ referee, level: depth });
          processReferral(referee, depth + 1); // Recursively fetch the next level of referrals
        });
      };

      // Start processing referrals from the given referrer
      processReferral(referrer, 1);
    }

    // Return unique referrals
    return getUniqueReferrals(referralTree);
  } catch (error) {
    console.error("Error fetching referrals:", error);
    return [];
  }
};

// Fetch the direct referrer for the connected user
export const fetchDirectReferrer = async () => {
  try {
    const userAddress = Cookies.get("connectedAddress");
    if (!userAddress) throw new Error("No connected address");

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      contractAddress,
      swssDrawABI,
      provider
    );

    // Fetch the direct referrer
    const referrer = await contract.referrers(userAddress);
    // console.log("Direct Referrer:", referrer);
    return referrer;
  } catch (error) {
    console.error("Error fetching referrer:", error);
    return null;
  }
};

// Fetch the referral chain starting from the connected user
export const fetchReferralChain = async () => {
  try {
    const userAddress = Cookies.get("connectedAddress");
    if (!userAddress) throw new Error("No connected address");

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const contract = new ethers.Contract(
      contractAddress,
      swssDrawABI,
      provider
    );

    const referralChain = [];
    let currentAddress = userAddress;

    // Traverse the referral chain
    while (currentAddress) {
      const referrer = await contract.referrers(currentAddress);
      if (!referrer || referrer === ethers.constants.AddressZero) break;
      referralChain.push(referrer);
      currentAddress = referrer;
    }

    // console.log("Referral Chain:", referralChain);
    return referralChain;
  } catch (error) {
    console.error("Error fetching referral chain:", error);
    return [];
  }
};

// Generate a referral link for the currently connected user
export const generateReferral = () => {
  const connectedAddress = Cookies.get("connectedAddress");

  if (!connectedAddress) {
    console.warn("No connected address");
    return;
  }

  const baseUrl = window.location.origin + "?referral_code=";
  const referralLink = `${baseUrl}${connectedAddress}`;
  // console.log("Generated Referral Link:", referralLink);
  return referralLink;
};
