import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  MenuItem,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DashboardPageTitle from "../../../component/DashboardPageTitle";
import { formatNumber } from "../../../utils/common";
import theme from "../../../theme/Theme";
import commonStyle from "../common.style";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  RedditIcon,
  RedditShareButton,
  TelegramIcon,
  TelegramShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import Cookies from "js-cookie";
import { AiOutlineShareAlt } from "react-icons/ai";
import { GiTrophy } from "react-icons/gi";
import { generateReferral } from "../../../api/referralService";
import { Winning } from "../../../assets";
import { winnersData } from "../../../api/ApiService";
import { formatISODate } from "../../../utils/dateUtils";
import { network } from "../../../config";

const MyWinningsPage = () => {
  const [winnings, setWinnings] = useState([]);
  const [shareDialog, setShareDialog] = useState(false);
  const [drawIds, setDrawIds] = useState([{ value: "", label: "- ALL -" }]);
  const [selectedDrawId, setSelectedDrawId] = useState(null);
  const title = "Share Your Big Win!";
  const line1 = "I just won";
  const description =
    "in the SwssDraw blockchain lucky draw! 🎉✨.\n\n It's completely transparent and powered by Blockchain Smart Contracts. Join the excitement and try your luck! Use my referral link to sign up and earn rewards: ";
  const line3 =
    "Don't miss your chance to win big! \n\n#SwssDraw #Blockchain #LuckyDraw #WinBig\n\n";

  const [shareDescription, setShareDescription] = useState(description);

  const fetchWinningsData = async (address, drawId) => {
    try {
      setWinnings([]);
      const winningsData = await winnersData(address, drawId); // Fetch winners using utility
      if (winningsData && winningsData?.data?.length > 0) {
        setWinnings(winningsData.data);
      }
    } catch (error) {
      console.error("Error fetching winnings:", error);
    }
  };

  useEffect(() => {
    const address = Cookies.get("connectedAddress");
    if (!address) {
      throw new Error("User address not found in cookies.");
    }
    if (selectedDrawId) {
      fetchWinningsData(address, selectedDrawId);
    } else {
      fetchWinningsData(address);
    }
  }, [selectedDrawId]);

  const getDrawsListData = async () => {
    const data = await import(`../../../data/${network}/DrawsList`);
    const drawIdz = data.DrawsList.map((draw) => {
      return {
        value: draw.id,
        label: draw.title,
      };
    }).sort((a, b) => parseInt(a) - parseInt(b));
    setDrawIds(drawIdz);
  };

  useEffect(() => {
    getDrawsListData();
  }, []);

  const handleShare = (draw, winner) => {
    const drawName = draw.name;
    const prizeAmount = `${draw.currencyTicker} ${formatNumber(
      winner.prizeAmount
    )}`;
    const referralLink = generateReferral();
    const message = `${line1} ${prizeAmount} ${description} ${referralLink}. ${line3}`;
    setShareDescription(message);
    setShareDialog(true);
  };

  const handleChange = (event) => {
    setSelectedDrawId(event.target.value);
  };

  return (
    <Box p={{ xs: 1, md: 3, lg: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DashboardPageTitle title={"My Wins"} />
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <Typography sx={commonStyle.selectLabel}>Draw Name: </Typography>
          <Select
            displayEmpty
            value={selectedDrawId}
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleChange}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px", // Adjust the height of the Menu container
                },
              },
            }}
            sx={{
              fontSize: "0.8rem",
              fontWeight: "600",
              color: theme.palette.text.primary,
              backgroundColor: "white",

              "& .MuiSelect-icon": {
                color: theme.palette.primary.main, // Color of the dropdown icon
              },
            }}
          >
            <MenuItem value={null} sx={commonStyle.selectMenuText}>
              {"- ALL -"}
            </MenuItem>
            {drawIds?.map((drawId) => (
              <MenuItem value={drawId.value} sx={commonStyle.selectMenuText}>
                {drawId.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Stack>

      <TableContainer component={Paper} sx={commonStyle.tableBackground}>
        <Table size="small">
          <TableHead>
            <TableRow sx={{ backgroundColor: theme.custom.tableHeaderBg }}>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "10%" }}>
                #
              </TableCell>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "10%" }}>
                Draw Id
              </TableCell>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "20%" }}>
                Draw Name
              </TableCell>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "10%" }}>
                Ticket Number
              </TableCell>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "10%" }}>
                Level
              </TableCell>

              <TableCell sx={commonStyle.ticketTitleStyle}>Win Prize</TableCell>
              <TableCell sx={{ ...commonStyle.ticketTitleStyle, width: "12%" }}>
                Date/Time
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {winnings && winnings?.length > 0 ? (
              winnings?.map((draw, drawIndex) => (
                <TableRow
                  key={`${draw._id}-${drawIndex}`}
                  sx={{
                    backgroundColor:
                      drawIndex % 2 === 0
                        ? theme.custom.tableRow
                        : theme.custom.white,
                  }}
                >
                  <TableCell
                    sx={{
                      ...commonStyle.ticketValueTitleStyle,
                      width: "10%",
                    }}
                  >
                    {drawIndex + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...commonStyle.ticketValueTitleStyle,
                      width: "10%",
                      cursor: "pointer",
                      color: theme.palette.primary.main,
                    }}
                    onClick={() => {
                      window.open(`/draw/${draw._id}`, "_blank");
                    }}
                  >
                    {draw.drawReference}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...commonStyle.ticketValueTitleStyle,
                      width: "20%",
                    }}
                  >
                    {draw.drawName}
                  </TableCell>
                  <TableCell
                    sx={{ ...commonStyle.ticketValueTitleStyle, width: "10%" }}
                  >
                    {Number(draw.ticketNumber)}
                  </TableCell>
                  <TableCell
                    sx={{
                      ...commonStyle.ticketValueTitleStyle,
                      width: "10%",
                    }}
                  >
                    {draw.level}
                  </TableCell>

                  <TableCell sx={commonStyle.ticketValueTitleStyle}>
                    {draw.currencyTicker ? draw.currencyTicker : "USDT"}{" "}
                    {parseFloat(draw.prizeAmount?.["$numberDecimal"])}
                  </TableCell>
                  <TableCell
                    sx={{ ...commonStyle.ticketValueTitleStyle, width: "12%" }}
                  >
                    {formatISODate(draw.createdAt)}
                  </TableCell>
                  <TableCell sx={commonStyle.ticketValueTitleStyle}>
                    <IconButton onClick={() => handleShare(draw, draw)}>
                      <AiOutlineShareAlt />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={6} sx={{ textAlign: "center" }}>
                  <Typography sx={commonStyle.noTransactions}>
                    No wins yet, but every day is a chance to turn your luck
                    around!
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={shareDialog} onClose={() => setShareDialog(false)}>
        <DialogTitle
          sx={{
            padding: "0.3em 1em",
          }}
        >
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <GiTrophy color="#FFD700" />
            <Typography
              sx={{
                fontSize: 20,
                fontWeight: 600,
                color: theme.custom.text,
              }}
            >
              Share Your Big Win!
            </Typography>
          </Stack>
        </DialogTitle>
        <Divider />
        <DialogContent
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          {/* <Box sx={{ position: "absolute", opacity: 0.2, top: -30 }}>
            <WinnerIcon />
          </Box> */}
          <Grid2 container spacing={2}>
            <Grid2 item size={{ xs: 12, md: 6 }}>
              <Box component={"img"} src={Winning} />
            </Grid2>
            <Grid2 item size={{ xs: 12, md: 6 }}>
              <Box>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    color: theme.custom.text,
                    marginBottom: "1em",
                  }}
                >
                  Congratulations on your amazing win! Spread the excitement and
                  inspire others by sharing your achievement on social media.
                  Let your friends join the fun with your referral link and earn
                  rewards together. The more, the merrier!
                </Typography>
                <Box
                  sx={{
                    padding: "5px 1em",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Stack direction={"row"} alignItems={"center"} spacing={3}>
                    <EmailShareButton
                      url={generateReferral()}
                      subject={title}
                      body={shareDescription}
                    >
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>

                    <WhatsappShareButton
                      title={`${title}\n\n${shareDescription}`}
                      url={generateReferral()}
                    >
                      <WhatsappIcon size={32} round={true} />
                    </WhatsappShareButton>
                    <RedditShareButton
                      title={`${title}\n\n${shareDescription}`}
                      url={generateReferral()}
                    >
                      <RedditIcon size={32} round={true} />
                    </RedditShareButton>

                    {/* <LinkedinShareButton
                      title={title}
                      summary={shareDescription}
                      url={referral}
                    >
                      <LinkedinIcon size={32} round={true} />
                    </LinkedinShareButton> */}
                  </Stack>
                  <Stack
                    direction={"row"}
                    alignItems={"center"}
                    spacing={3}
                    mt={1}
                  >
                    <TwitterShareButton
                      title={`${title}\n\n${shareDescription}`}
                      url={generateReferral()}
                      hashtags={[
                        "#SwssDraw",
                        "#Blockchain",
                        "#LuckyDraw",
                        "#WinBig",
                      ]}
                    >
                      <TwitterIcon size={32} round={true} />
                    </TwitterShareButton>

                    <FacebookShareButton
                      url={`${generateReferral()}&t=${title}&text=${shareDescription}`}
                    >
                      <FacebookIcon size={32} round={true} />
                    </FacebookShareButton>

                    <TelegramShareButton
                      title={`${title}\n\n${shareDescription}`}
                      url={generateReferral()}
                    >
                      <TelegramIcon size={32} round={true} />
                    </TelegramShareButton>
                  </Stack>
                </Box>
              </Box>
              <Stack
                direction={"row"}
                justifyContent={"flex-end"}
                sx={{ width: "100%" }}
              >
                <Button
                  variant="contained"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.primary.main,
                    color: theme.custom.white,
                    fontWeight: 600,
                    borderRadius: "4px",
                    padding: "3px 20px",
                    transition: "all 0.3s ease",
                    "&:hover": {
                      backgroundColor: theme.palette.primary.dark,
                    },
                  }}
                  onClick={() => setShareDialog(false)}
                >
                  Close
                </Button>
              </Stack>
            </Grid2>
          </Grid2>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default MyWinningsPage;
