import { useEffect, useState } from "react";
import { winnersData } from "../../api/ApiService";
import { NoRecordFound, TableBg } from "../../assets";
import { formatISODate } from "../../utils/dateUtils";
import theme from "../../theme/Theme";
import { Link } from "react-router-dom";
import { SpinnerInfinity } from "spinners-react";
import { network } from "../../config";

const {
  Box,
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Stack,
  MenuItem,
  Select,
  Typography,
  TextField,
  Pagination,
  PaginationItem,
} = require("@mui/material");

const LeaderboardPage = () => {
  const [topWinners, setTopWinners] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [winnersList, setWinnersList] = useState([]);
  const [drawIds, setDrawIds] = useState([{ value: "", label: "- ALL -" }]);
  const [selectedDrawId, setSelectedDrawId] = useState(null);
  const [searchAddress, setSearchAddress] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const itemsPerPage = 10;

  const getWinnersData = async (drawId = null) => {
    setLoading(true);
    setTopWinners([]);
    const response = await winnersData(null, drawId);
    if (response && response?.data?.length > 0) {
      const sortedData = response.data.sort(
        (a, b) =>
          parseFloat(b?.prizeAmount?.["$numberDecimal"]) -
          parseFloat(a?.prizeAmount?.["$numberDecimal"])
      );

      const totalPage = Math.ceil(sortedData.length / itemsPerPage);
      setTotalPages(totalPage);
      const startIndex = (currentPage - 1) * itemsPerPage;
      const endIndex = startIndex + itemsPerPage;
      const paginatedItems = sortedData.slice(startIndex, endIndex);

      setTopWinners(paginatedItems);
      setWinnersList(sortedData);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (selectedDrawId) {
      getWinnersData(selectedDrawId);
    } else {
      getWinnersData();
    }
  }, [selectedDrawId, currentPage]);

  const getDrawIds = async () => {
    const drawsData = await import(`../../data/${network}/DrawsList`);
    const drawIdz = drawsData.DrawsList.map((draw) => {
      return {
        value: draw.id,
        label: draw.title,
      };
    }).sort((a, b) => parseInt(a) - parseInt(b));
    setDrawIds(drawIdz);
  };

  useEffect(() => {
    getDrawIds();
  }, []);

  const handleChange = (event) => {
    setSelectedDrawId(event.target.value);
  };

  const handleSearchChange = (event) => {
    if (event.target.value === "") {
      getWinnersData();
    } else {
      const filterData = winnersList.filter((item) => {
        if (
          item.winnerAddress
            .toLowerCase()
            .includes(event.target.value.toLowerCase())
        ) {
          return item;
        }
      });
      setTopWinners(filterData);
    }
    setSearchAddress(event.target.value);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  return (
    <Container sx={{ py: 3 }}>
      <Typography sx={styles.pageTitle}>Winners </Typography>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        mt={4}
        mb={2}
      >
        <Stack direction={"row"} alignItems={"center"} gap={1} sx={{ flex: 1 }}>
          <Typography sx={styles.selectLabel}>Draw Name: </Typography>
          <Select
            displayEmpty
            value={selectedDrawId}
            inputProps={{ "aria-label": "Without label" }}
            onChange={handleChange}
            size="small"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "200px", // Adjust the height of the Menu container
                },
              },
            }}
            sx={styles.selectStyle}
          >
            <MenuItem value={null} sx={styles.selectMenuText}>
              {"- ALL -"}
            </MenuItem>
            {drawIds?.map((drawId) => (
              <MenuItem value={drawId.value} sx={styles.selectMenuText}>
                {drawId.label}
              </MenuItem>
            ))}
          </Select>
        </Stack>
        <TextField
          size="small"
          variant="outlined"
          placeholder="Search by address"
          sx={styles.searchField}
          value={searchAddress}
          slotProps={{
            input: {
              style: {
                fontSize: "0.8rem",
                fontWeight: 500,
                color: theme.custom.emperor,
              },
            },
          }}
          onChange={(e) => handleSearchChange(e)}
        />
      </Box>

      <TableContainer component={Paper} sx={styles.tableContainerStyle}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={styles.tableHeadCell}>#</TableCell>
              <TableCell sx={styles.tableHeadCell}>Draw ID</TableCell>
              <TableCell sx={styles.tableHeadCell}>Draw Name</TableCell>
              <TableCell sx={styles.tableHeadCell}>Ticket Number</TableCell>
              <TableCell sx={styles.tableHeadCell}>Level</TableCell>
              <TableCell sx={styles.tableHeadCell}>Win Prize</TableCell>
              <TableCell sx={styles.tableHeadCell}>Date/Time</TableCell>
              <TableCell sx={styles.tableHeadCell}>Winner Address</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell sx={styles.tableBodyCell} colSpan={8}>
                  <SpinnerInfinity
                    color={theme.palette.primary.dark}
                    secondaryColor={theme.palette.primary.main}
                    size={40}
                    enabled={true}
                  />
                </TableCell>
              </TableRow>
            ) : (
              <>
                {topWinners.length > 0 ? (
                  topWinners.map((item, index) => (
                    <TableRow
                      sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}
                      key={item._id}
                    >
                      <TableCell sx={styles.tableBodyCell}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {item.drawReference}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        <Link
                          to={`/draw/${item.drawReference}`}
                          style={styles.linkStyle}
                        >
                          <Typography sx={styles.linkStyle}>
                            {item.drawName}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {item.ticketNumber}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {item.level}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {`USDT ${parseFloat(
                          item.prizeAmount?.["$numberDecimal"]
                        )}`}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {formatISODate(item.createdAt)}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {item.winnerAddress}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}>
                    <TableCell sx={styles.tableBodyCell} colSpan={8}>
                      <Stack direction={"column"} alignItems={"center"} gap={1}>
                        <Box
                          component={"img"}
                          src={NoRecordFound}
                          sx={styles.noRecordImage}
                        />
                        <Typography sx={styles.noRecordText}>
                          No wins yet, but every day is a chance to turn your
                          luck around!
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Box sx={styles.paginationContainer}>
        <Pagination
          count={totalPages}
          shape="rounded"
          size="large"
          sx={styles.pagination}
          onChange={handlePageChange}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              sx={{
                backgroundColor: item.selected
                  ? theme.custom.white
                  : theme.palette.primary.main,
                borderColor: item.selected
                  ? theme.palette.primary.main
                  : theme.custom.white,
                borderWidth: item.selected ? "2px" : "1px",
                borderRadius: "12px",
                color: item.selected
                  ? theme.palette.primary.main
                  : theme.custom.white,
                "&:hover": {
                  backgroundColor: theme.palette.primary.main,
                },
                ...(item.type === "previous" || item.type === "next"
                  ? {
                      backgroundColor: "transparent",
                      color: theme.palette.primary.main,
                      borderRadius: "50%",
                      "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: theme.custom.white,
                      },
                    }
                  : {}),
              }}
            />
          )}
        />
      </Box>
    </Container>
  );
};
export default LeaderboardPage;
const styles = {
  pageTitle: {
    fontSize: "clamp(1.5rem, 2.5vw, 2rem)",
    color: theme.palette.primary.main,
    fontWeight: 700,
    flex: 1,
  },
  tableBodyCell: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: "rgba(0,0,0,1)",
    textAlign: "center",
  },
  tableHeadCell: {
    fontSize: { xs: "0.8rem", lg: "0.8rem" },
    fontWeight: 700,
    color: "rgba(0, 0, 0, 1)",
    textAlign: "center",
  },
  tableContainerStyle: {
    width: "100%",
    borderRadius: "10px",
    backgroundImage: `url(${TableBg})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "110% 110%",
    backgroundPosition: "center",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
  },
  selectMenuText: {
    color: theme.palette.text.primary,
    fontSize: "0.8rem",
    fontWeight: 600,
  },
  selectLabel: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 600,
  },
  linkStyle: {
    color: theme.custom.link,
    fontSize: "0.8rem",
    fontWeight: 500,
    textDecoration: "none",
  },
  noRecordImage: {
    width: "10em",
  },
  noRecordText: {
    fontSize: "0.8rem",
    fontWeight: 500,
    color: theme.custom.emperor,
  },
  searchField: {
    flex: 1,
  },
  paginationContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2em",
  },
  selectStyle: {
    fontSize: "0.8rem",
    fontWeight: "600",
    color: theme.palette.text.primary,
    backgroundColor: "white",

    "& .MuiSelect-icon": {
      color: theme.palette.primary.main, // Color of the dropdown icon
    },
  },
};
