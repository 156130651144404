const TotalWinIcon = ({ width = 24, height = 24, color = "#BF1522" }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 22 28" fill="none">
      <path
        d="M6.85627 10.8885C6.86975 9.85739 7.28533 8.87234 8.01447 8.14321C8.7436 7.41407 9.72865 6.99849 10.7597 6.98501C11.7908 6.99849 12.7758 7.41407 13.505 8.14321C14.2341 8.87234 14.6497 9.85739 14.6632 10.8885C14.6497 11.9195 14.2341 12.9046 13.505 13.6337C12.7758 14.3628 11.7908 14.7784 10.7597 14.7919C9.72865 14.7784 8.7436 14.3628 8.01447 13.6337C7.28533 12.9046 6.86975 11.9195 6.85627 10.8885ZM10.7597 22.5988L15.9643 23.8999V19.8924C14.3947 20.8376 12.5918 21.3244 10.7597 21.2976C8.92766 21.3244 7.12472 20.8376 5.55512 19.8924V23.8999M10.7597 3.08157C9.73437 3.06286 8.71603 3.25405 7.76729 3.64339C6.81856 4.03273 5.95947 4.61199 5.24285 5.34556C4.50134 6.06298 3.91538 6.92529 3.52141 7.87886C3.12745 8.83244 2.93391 9.85688 2.95283 10.8885C2.93927 11.9135 3.13542 12.9305 3.52922 13.877C3.92303 14.8234 4.50618 15.6794 5.24285 16.3923C5.95613 17.1329 6.81371 17.7194 7.76261 18.1156C8.7115 18.5117 9.73155 18.709 10.7597 18.6953C11.7879 18.709 12.8079 18.5117 13.7568 18.1156C14.7057 17.7194 15.5633 17.1329 16.2766 16.3923C17.0132 15.6794 17.5964 14.8234 17.9902 13.877C18.384 12.9305 18.5802 11.9135 18.5666 10.8885C18.5855 9.85688 18.392 8.83244 17.998 7.87886C17.604 6.92529 17.0181 6.06298 16.2766 5.34556C15.56 4.61199 14.7009 4.03273 13.7521 3.64339C12.8034 3.25405 11.7851 3.06286 10.7597 3.08157ZM21.1689 10.8885C21.1423 12.1375 20.8911 13.3716 20.4272 14.5317C20.0114 15.7129 19.3806 16.8069 18.5666 17.7585V27.8034L10.7597 25.2011L2.95283 27.8034V17.7585C1.26874 15.8672 0.342074 13.4208 0.35053 10.8885C0.327598 9.52301 0.584785 8.16735 1.10621 6.90518C1.62764 5.64301 2.40225 4.50111 3.3822 3.54998C4.33686 2.56124 5.48403 1.77864 6.75291 1.2505C8.02179 0.722361 9.38545 0.459885 10.7597 0.479272C12.134 0.459885 13.4976 0.722361 14.7665 1.2505C16.0354 1.77864 17.1826 2.56124 18.1372 3.54998C19.1172 4.50111 19.8918 5.64301 20.4132 6.90518C20.9346 8.16735 21.1918 9.52301 21.1689 10.8885Z"
        fill={color}
      />
    </svg>
  );
};
export default TotalWinIcon;
