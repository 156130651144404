import ScrollToTop from "./hooks/ScrollToTop";
import AuthGuard from "./layout/AuthGuard";
import DashboardLayout from "./layout/dashboardLayout/DashboardLayout";
import ContactUs from "./pages/contactus/ContactUs";
import DashboardHomePage from "./pages/dashboard/DashboardHomePage";
import MyTicketsPage from "./pages/dashboard/mytickets/MyTicketsPage";
import MyWinningsPage from "./pages/dashboard/mywinnings/MyWinningsPage";
import MyReferralsPage from "./pages/dashboard/referrals/MyReferralsPage";
import MyTransactionsPage from "./pages/dashboard/transactions/MyTransactionsPage";
import DisclaimerPage from "./pages/disclaimer/DisclaimerPage";
import DrawPage from "./pages/draw/Draw";
import DrawDetails from "./pages/draw/drawdetails/DrawDetails";
import Home from "./pages/home/Home";
import LeaderboardPage from "./pages/leaderboard/LeaderboardPage";
import PrivacyPolicyPage from "./pages/privacyPolicy/PrivacyPolicyPage";
import ReferralsPage from "./pages/referrals/ReferralsPage";
import TermsConditionPage from "./pages/termsCondition/TermsConditionPage";
import TestimonialsPage from "./pages/testimonials/TestimonialsPage";

const { Routes, Route, BrowserRouter, Navigate } = require("react-router-dom");
const { default: MainLayout } = require("./layout/MainLayout");

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Home />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/testimonials" element={<TestimonialsPage />} />
          <Route path="/winners" element={<LeaderboardPage />} />
          <Route path="/referrals" element={<ReferralsPage />} />
          <Route path="/draw" element={<DrawPage />} />
          <Route path="/disclaimer" element={<DisclaimerPage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
          <Route
            path="/terms-and-conditions"
            element={<TermsConditionPage />}
          />
          <Route path="/draw/:id" element={<DrawDetails />} />
        </Route>

        <Route
          path="/dashboard"
          element={
            <AuthGuard>
              <DashboardLayout />
            </AuthGuard>
          }
        >
          <Route path="dashboard" element={<DashboardHomePage />} />
          <Route index element={<MyTicketsPage />} />
          <Route path="tickets" element={<MyTicketsPage />} />
          <Route path="referrals" element={<MyReferralsPage />} />
          <Route path="transactions" element={<MyTransactionsPage />} />
          <Route path="winnings" element={<MyWinningsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AppRoutes;
