import { alpha, Stack, Typography } from "@mui/material";
import theme from "../theme/Theme";
import { IoNotifications } from "react-icons/io5";
const DashboardPageTitle = ({ title, description }) => {
  return (
    <Stack direction={"column"} alignItems={"flex-start"} gap={1}>
      <Typography sx={styles.titleText}>{title}</Typography>
      {description && (
        <Stack direction={"row"} alignItems={"center"} gap={1}>
          <IoNotifications
            size={16}
            color={alpha(theme.palette.primary.main, 0.8)}
          />
          <Typography sx={styles.descriptionText}>{description}</Typography>
        </Stack>
      )}
    </Stack>
  );
};
export default DashboardPageTitle;
const styles = {
  titleText: {
    fontSize: { xs: "1.8rem", lg: "2rem" },
    fontWeight: 700,
    textTransform: "uppercase",
    borderBottom: `1px solid ${theme.palette.primary.main}`,
  },
  descriptionText: {
    fontSize: { xs: "0.8rem", lg: "0.8rem" },
    fontWeight: 500,
    color: alpha(theme.palette.primary.main, 0.8),
  },
};
