import { ethers } from "ethers";
import contractAbi from "../abi/swssDraw";
import { contractAddress } from "../config";
import { blockchainNetworks } from "../config/blockchain";
import {
  weiToEther,
  getDateTimeFromEpoch,
  hexToDecimal,
} from "../utils/common";

export const fetchParticipantCount = async (drawId, blockchain) => {
  if (blockchain === 'swssMainnet') {
    return 0;
  }
  try {
    // need to remove
    const blockchainData = blockchainNetworks[blockchain];
    // Replace with your Infura or Alchemy API URL
    const provider = new ethers.providers.JsonRpcProvider(
      blockchainData.infuraUrl
    );

    // Create a contract instance
    const contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      provider
    );
    // Call the smart contract function
    const count = await contract.participantCount(drawId);

    // console.log(`${drawId} sold tickets: ${count} `);
    return count.toString(); // Convert it to string to handle large numbers
  } catch (error) {
    console.error("Error fetching participant count:", error);
    return 0;
  }
};

export const getDrawConfigData = async (drawId, blockchain) => {
  if (blockchain === 'swssMainnet') {
    return null;
  }
  try {
    const blockchainData = blockchainNetworks[blockchain];
    // Replace with your Infura or Alchemy API URL
    const provider = new ethers.providers.JsonRpcProvider(
      blockchainData.infuraUrl
    );

    // Create a contract instance
    const contract = new ethers.Contract(
      contractAddress,
      contractAbi,
      provider
    );
    // Call the smart contract function
    const drawConfig = await contract.getDrawConfig(drawId);
    const ticketPrice = weiToEther(drawConfig.ticketPrice);
    const startTime = getDateTimeFromEpoch(drawConfig.startTime);
    const endTime = getDateTimeFromEpoch(drawConfig.endTime);
    const maxParticipants = hexToDecimal(drawConfig[4]).toString();
    const withdrawalFee = (drawConfig[12][1] / 100).toString();
    const referralLevels = drawConfig.referralPercentages;

    const data = {
      ticketPrice,
      startTime,
      endTime,
      maxParticipants,
      withdrawalFee,
      referralLevels,
    };
    return data; // Convert it to string to handle large numbers
  } catch (error) {
    console.error("Error fetching draw config:", error);
    return null;
  }
};
