import { Box, Divider, Grid2, Typography } from "@mui/material";
import { styles } from "../styles";
import { OvalBg } from "../../../assets";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../../theme/Theme";
import AOS from "aos";
import "aos/dist/aos.css";
import { DateTime } from "luxon";
import DrawTicketComponent from "../../../component/DrawTicketComponent";
import { network } from "../../../config";
const ActiveCompetitions = () => {
  const [otherDraws, setOtherDraws] = useState([]);
  const [swssDraws, setSwssDraws] = useState([]);
  const navigate = useNavigate();

  const getDrawsData = async () => {
    const drawsData = await import(`../../../data/${network}/DrawsList`);

    const activeOtherChains = drawsData.DrawsList.filter(
      (draw) =>
        isNotExpired(draw.endISODate) &&
        draw.blockchain !== "swssMainnet" &&
        draw.trending === true
    )
      .slice(0, 3)
      .sort((a, b) => new Date(a.endISODate) - new Date(b.endISODate));
    setOtherDraws(activeOtherChains);

    const activeSwssChains = drawsData.DrawsList.filter(
      (draw) =>
        isNotExpired(draw.endISODate) &&
        draw.blockchain === "swssMainnet" &&
        draw.trending === true
    )
      .slice(0, 3)
      .sort((a, b) => new Date(a.endISODate) - new Date(b.endISODate));
    setSwssDraws(activeSwssChains);
  };

  useEffect(() => {
    getDrawsData();
    AOS.init({ duration: 1000 });
  }, []);

  const isNotExpired = (isoDate) => {
    const givenDate = DateTime.fromISO(isoDate, { zone: "utc" });
    const now = DateTime.now().toUTC();
    return givenDate > now;
  };

  const handleCompetitionViewMoreClicked = () => {
    navigate("/draw");
  };

  const handleTestimonialViewMoreClicked = () => {
    navigate("/testimonials");
  };

  return (
    <Box
      sx={{
        position: "relative",
        top: { xs: -30, sm: -30, md: -50, lg: -50 },
      }}
    >
      <div style={styles.activeCompetitions}>
        <img src={OvalBg} style={styles.activeCompetitionBg} alt="oval_image" />
        <Typography sx={styles.titleText}>
          Trending <span style={styles.titleHighLight}>Competitions</span>
        </Typography>
        <Typography
          sx={{
            ...styles.subTitleText,
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          Explore the top trending draws and get your chance to win big with
          these exciting ongoing competitions
        </Typography>
        {otherDraws && otherDraws.length > 0 && (
          <Divider
            flexItem
            textAlign="center"
            sx={{ mt: { xs: 2, sm: 2, md: 2, lg: 2 } }}
          >
            <Typography sx={styles.smartChainTitleText}>
              BNB Smart Chain
            </Typography>
          </Divider>
        )}

        <Grid2
          container
          spacing={{ xs: 4, md: 2 }}
          pt={{ xs: 4, md: 2 }}
          px={{ xs: 2, sm: 2, md: 5, lg: 15, xl: 15 }}
          alignItems="center"
          justifyContent="center"
        >
          {otherDraws.map((item, index) => (
            <Grid2
              item
              size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
              key={index}
              onClick={() => {
                navigate(`/draw/${item.id}`);
              }}
              sx={{
                cursor: "pointer",
                transition: "all 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <DrawTicketComponent drawData={item} />
            </Grid2>
          ))}
        </Grid2>
        {swssDraws?.length > 0 && (
          <>
            <Divider
              flexItem
              textAlign="center"
              sx={{
                mt: 4,
                "&::before, &::after": {
                  borderColor:
                    otherDraws && otherDraws.length > 0
                      ? theme.custom.white
                      : theme.custom.emperor, // Customize the divider line color
                },
              }}
            >
              <Typography
                sx={{
                  ...styles.smartChainTitleText,
                  color:
                    otherDraws && otherDraws.length > 0
                      ? theme.custom.white
                      : theme.custom.emperor,
                }}
              >
                SWSS Smart Chain
              </Typography>
            </Divider>
            <Grid2
              container
              spacing={{ xs: 4, md: 2 }}
              pt={{ xs: 4, md: 2 }}
              px={{ xs: 2, sm: 2, md: 5, lg: 15, xl: 15 }}
              alignItems="center"
              justifyContent="center"
            >
              {swssDraws.map((item, index) => (
                <Grid2
                  item
                  size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
                  key={index}
                  onClick={() => {
                    navigate(`/draw/${item.id}`);
                  }}
                  sx={{
                    cursor: "pointer",
                    transition: "all 0.3s ease-in-out",
                    "&:hover": {
                      transform: "scale(1.05)",
                    },
                  }}
                >
                  <DrawTicketComponent drawData={item} />
                </Grid2>
              ))}
            </Grid2>
          </>
        )}
      </div>
    </Box>
  );
};
export default ActiveCompetitions;
