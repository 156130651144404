import React from "react";
import { Navigate } from "react-router-dom";
import Cookies from "js-cookie";

const AuthGuard = ({ children }) => {
  const storedAddress = Cookies.get("connectedAddress");
  const storedWalletType = Cookies.get("connectedWalletType");
  const isLoggedIn =
    storedAddress !== undefined && storedWalletType !== undefined;

  return isLoggedIn ? children : <Navigate to="/" replace />;
};

export default AuthGuard;
