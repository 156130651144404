import axios from "axios";
import { apiURL } from "../config";
import Cookies from "js-cookie";

export const drawTicketApi = async (address) => {
  const response = await axios.get(`${apiURL}/draw-tickets?address=${address}`);
  return response.data;
};

export const winnersData = async (address = null, drawId = null) => {
  let sUrl = "";
  if (address && drawId) {
    sUrl = `?address=${address}&drawId=${drawId}`;
  } else if (address) {
    sUrl = `?address=${address}`;
  } else if (drawId) {
    sUrl = `?drawId=${drawId}`;
  }
  const response = await axios.get(`${apiURL}/draw-winners${sUrl}`);
  return response.data;
};

export const fetchTransactionData = async () => {
  try {
    const address = Cookies.get("connectedAddress");
    const response = await axios.get(`${apiURL}/transactions/${address}`);
    return response ? response?.data : null;
  } catch (error) {
    console.log("Error fetching transactions:", error);
  }
};

export const fetchReferralsList = async () => {
  try {
    const address = Cookies.get("connectedAddress");
    const response = await axios.get(`${apiURL}/referrals/${address}`);
    return response ? response?.data : null;
  } catch (error) {
    console.log("Error fetching referrals:", error);
  }
};
