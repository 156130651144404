export const proofFactor = async (txHash, address, qty, drawName) => {
    const url = 'https://callbacks-api.one.store/v1/events/TnKj553hTjCsTlTlWObOSw';

    const shortAddress = `${address.slice(0, 6)}...${address.slice(-4)}`;
    const drawNameShort = drawName.length > 20 ? `${drawName.slice(0, 20)}...` : drawName;

    const popupMessage = `${shortAddress} purchased ${qty} Ticket(s) for ${drawNameShort}. Click here to view all blockchain tickets!`;

    const requestBody = {
        message: popupMessage
    };

    try {
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(requestBody),
        });

        const text = await response.text();
    } catch (error) {
        console.error("Error calling proofFactor:", error);
    }
};
