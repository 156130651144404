const TotalParticipatesIcon = ({ width = 24, height = 24 }) => {
  return (
    <svg width={width} height={height} viewBox="0 0 30 25" fill="none">
      <path
        d="M27.4878 12.7855L29.5728 14.8854L19.8476 24.7L14.6797 19.4874L16.7647 17.3875L19.8476 20.4852L27.4878 12.7855ZM10.9564 19.4874L15.4244 23.9553H0.53125V20.9767C0.53125 17.6853 5.86298 15.0195 12.4457 15.0195L15.2605 15.1833L10.9564 19.4874ZM12.4457 0.126343C14.0257 0.126343 15.5409 0.753979 16.6581 1.87118C17.7753 2.98838 18.403 4.50363 18.403 6.08359C18.403 7.66355 17.7753 9.1788 16.6581 10.296C15.5409 11.4132 14.0257 12.0408 12.4457 12.0408C10.8658 12.0408 9.35053 11.4132 8.23333 10.296C7.11613 9.1788 6.48849 7.66355 6.48849 6.08359C6.48849 4.50363 7.11613 2.98838 8.23333 1.87118C9.35053 0.753979 10.8658 0.126343 12.4457 0.126343Z"
        fill="#BF1522"
      />
    </svg>
  );
};
export default TotalParticipatesIcon;
