import { Box, Divider, Stack, Typography } from "@mui/material";
import { DateTime } from "luxon";
import theme from "../theme/Theme";
import { formatNumber } from "../utils/common";
import { blockchainNetworks } from "../config/blockchain";
import { ClockIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import {
  fetchParticipantCount,
  getDrawConfigData,
} from "../api/drawDetailsService";
import { GiTrophy } from "react-icons/gi";

const DrawTicketComponent = ({ drawData }) => {
  const [soldTickets, setSoldTickets] = useState({});
  const [configData, setConfigData] = useState({
    ticketPrice: 0,
    startTime: "",
    endTime: "",
    maxParticipants: 0,
    withdrawalFee: 0,
  });
  const [fetchedConfig, setFetchedConfig] = useState(false);
  const calculateRemainingTime = (isoDate) => {
    const now = DateTime.now(); // Current DateTime
    const target = DateTime.fromISO(isoDate); // Target DateTime

    // If the target date is in the past
    if (target < now) {
      return "Completed";
    }

    // If the target date is today
    if (target.hasSame(now, "day")) {
      const diff = target.diff(now, ["hours", "minutes", "seconds"]).toObject();
      const hours = Math.floor(diff.hours || 0);
      const minutes = Math.floor(diff.minutes || 0);
      const seconds = Math.floor(diff.seconds || 0);
      if (hours > 0) {
        return `${hours.toString().padStart(2, "0")} Hours remaining`;
      } else if (minutes > 0) {
        return `${minutes.toString().padStart(2, "0")} Minutes remaining`;
      } else {
        return `${seconds.toString().padStart(2, "0")} Seconds remaining`;
      }
    }

    // If the target date is in the future
    const days = Math.floor(target.diff(now, "days").days);
    return `${days} Days remaining`;
  };

  const getSoldTicketPercentages = (soldTickets, totalTickets) => {
    const soldPercentages = (soldTickets / totalTickets) * 100;
    return soldPercentages;
  };

  const getBlockchainIcon = (blockchain) => {
    return blockchainNetworks[blockchain].iconUrl;
  };

  const fetchSoldTickets = async (drawId, blockchain) => {
    try {
      const response = await fetchParticipantCount(drawId, blockchain);
      setSoldTickets((prevState) => ({
        ...prevState,
        [drawId]: response,
      }));

      const drawConfigData = await getDrawConfigData(drawId, blockchain);
      if (drawConfigData) {
        setConfigData(drawConfigData);
      }
      setFetchedConfig(true);
    } catch (error) {
      console.error("Error fetching sold tickets:", error);
    }
  };

  useEffect(() => {
    fetchSoldTickets(drawData.id, drawData.blockchain);
  }, []);

  return (
    <Box sx={styles.competitionCard}>
      <Box sx={styles.imageContainer}>
        <img
          src={drawData.bannerImage}
          style={styles.competitionCardImage}
          alt="epic_win"
        />

        <Box sx={styles.titleContainer}>
          <Typography sx={styles.competitionCardTitle}>
            {drawData.title}
          </Typography>
        </Box>
      </Box>

      <Box sx={styles.competitionCardContent}>
        {calculateRemainingTime(drawData.endISODate) === "Completed" && (
          <Typography sx={styles.completedRibbonText}>Completed</Typography>
        )}
        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          mx={2}
          sx={{
            borderLeft: `4px solid ${theme.palette.primary.main}`,
          }}
          gap={1}
        >
          <Box>
            <Typography sx={styles.totalPoolPriceText}>
              Total Pool Prize
            </Typography>
            <Stack direction={"row"} alignItems={"center"} gap={0.5}>
              <Typography sx={styles.totalPoolPriceTicker}>
                {drawData.currencyTicker}
              </Typography>
              <Typography sx={styles.totalPoolPriceValueText}>
                {formatNumber(drawData.totalPrice)}
              </Typography>
            </Stack>
          </Box>

          <img
            src={getBlockchainIcon(drawData.blockchain)}
            alt="eth"
            width={24}
            height={24}
            style={styles.blockchainIcon}
          />
        </Stack>

        <Stack
          direction={"row"}
          alignItems={"center"}
          justifyContent={"space-between"}
          spacing={{ xs: 2, sm: 2, md: 1, lg: 2, xl: 2 }}
          mt={4}
          mx={2}
        >
          <Box sx={{ flex: 1 }}>
            <Typography sx={styles.otherTitleText}>1st Winner</Typography>
            <Typography sx={styles.otherPriceValueText}>
              {drawData.currencyTicker} {formatNumber(drawData.firstWinner)}
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography sx={{ ...styles.otherTitleText, textAlign: "right" }}>
              Total Winners
            </Typography>
            <Typography
              sx={{
                ...styles.otherPriceValueText,
                textAlign: "right",
              }}
            >
              {drawData.totalWinners}
            </Typography>
          </Box>
        </Stack>
        <Stack
          direction={"row"}
          spacing={2}
          alignItems={"center"}
          mt={3}
          mx={2}
          justifyContent={"space-between"}
        >
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <ClockIcon width={24} />
            <Typography sx={styles.daysText}>
              {`${
                fetchedConfig
                  ? configData?.endTime
                    ? calculateRemainingTime(configData.endTime)
                    : calculateRemainingTime(drawData.endISODate)
                  : "- : - : -"
              }`}
            </Typography>
          </Stack>
          <Divider
            orientation="vertical"
            flexItem
            sx={{ backgroundColor: "rgba(82, 82, 82, 1)" }}
          />
          <Stack direction={"row"} alignItems={"center"} spacing={1}>
            <GiTrophy width={24} />
            <Typography sx={styles.daysText}>
              {`${drawData.winingProbability} Winning Probability`}
            </Typography>
          </Stack>
        </Stack>
        <Box mx={2} my={3}>
          <Stack
            direction={"row"}
            alignItems={"center"}
            justifyContent={"space-between"}
            mb={0.3}
          >
            <Typography sx={styles.progressText}>
              {`Sold: ${soldTickets[drawData.id] || 0}`}
            </Typography>
            <Typography sx={styles.progressText}>
              {`Remaining: ${formatNumber(
                drawData.totalTickets - (soldTickets[drawData.id] || 0)
              )}`}
            </Typography>
          </Stack>
          <Box sx={styles.progressBarContainer}>
            <Box
              sx={{
                ...styles.soldTickers,
                width: `${getSoldTicketPercentages(
                  soldTickets[drawData.id] || 0,
                  drawData.totalTickets
                )}%`,
              }}
            ></Box>
          </Box>
        </Box>

        <Box sx={styles.pricePerTicket}>
          <Typography sx={styles.joinButtonText}>
            Price / Ticket{" "}
            <span style={{ fontWeight: 700 }}>
              {drawData.currencyTicker} {drawData.pricePerTicket}
            </span>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
export default DrawTicketComponent;

const styles = {
  competitionCard: {
    backgroundColor: "#FFFFFF",
    borderRadius: "1em",
    margin: { sx: 1, md: 2, lg: 3 },
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px",
  },
  imageContainer: {
    position: "relative",
    width: "100%",
    height: 240,
  },

  completedRibbonText: {
    color: "rgba(193, 20, 20, 0.3)",
    fontSize: { xs: "2.5rem", sm: "2.5rem", lg: "3rem" },
    fontWeight: "700",
    textTransform: "uppercase",
    position: "absolute",
    textAlign: "center",
    left: 0,
    right: 0,
    top: "40%",
    transform: "rotate(-45deg)",
  },
  titleContainer: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    padding: "0.5em 1em",
    zIndex: 3,
  },
  competitionCardImage: {
    position: "absolute",
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    height: 240,
    width: "100%",
    objectFit: "fill",
    borderRadius: "1em 1em 0 0",
    zIndex: 2,
  },
  competitionCardTitle: {
    fontSize: { xs: "4.2vw", lg: "1.8vw" },
    fontWeight: "800",
    color: "rgba(255, 255, 255, 1)",
    textTransform: "uppercase",
    textAlign: "center",
  },
  totalPoolPriceText: {
    fontSize: { xs: "3.2vw", lg: "1.25vw" },
    fontWeight: 500,
    color: "rgba(51, 51, 51, 1)",
    display: "block",
    marginLeft: 2,
  },
  totalPoolPriceTicker: {
    fontWeight: 600,
    fontSize: { xs: "2.5vw", lg: "1vw" },
    color: theme.palette.primary.main,
    marginLeft: 2,
  },

  totalPoolPriceValueText: {
    fontWeight: 600,
    fontSize: { xs: "3.3vw", lg: "1.28vw" },
    color: theme.palette.primary.main,
  },
  otherTitleText: {
    fontSize: { xs: "3.1vw", lg: "1vw" },
    fontWeight: 600,
    color: "#706F6F",
    lineHeight: 1.2,
  },
  blockchainIcon: {
    margin: "0 0.5em",
  },
  otherPriceValueText: {
    fontWeight: 600,
    fontSize: { xs: "3.2vw", lg: "1.2vw" },
    color: "#3F3F42",
    lineHeight: 1.2,
  },
  progressBarContainer: {
    position: "relative",
    height: "10px",
    width: "100%",
    borderRadius: "10em",
    overflow: "hidden",
    backgroundColor: "#DADADA",
  },
  soldTickers: {
    backgroundColor: "#0093E9",
    backgroundImage:
      "linear-gradient(90deg, #2AF598 0%, #eae608 31%, #fb9b03 65%, #ff0004 100%)",
    height: "10px",
    borderRadius: "10em",
  },
  progressText: {
    fontSize: { xs: "2vw", lg: "0.9vw" },
    fontWeight: 600,
    color: "#3E3E3E",
    lineHeight: 1.2,
  },
  competitionCardDescription: {
    fontSize: "1rem",
    fontWeight: "600",
    color: "rgba(82, 82, 82, 1)",
  },
  competitionCardButton: {
    border: `1px solid ${theme.palette.primary.main}`,
  },
  competitionCardContent: {
    paddingTop: "0.5em",
    position: "relative",
  },
  bookmarkIcon: {
    border: "1.4px solid rgba(191, 21, 34, 1)",
    borderRadius: "50%",
    padding: "0.2em",
    width: "1.8em",
    height: "1.8em",
    "&:hover": {
      cursor: "pointer",
    },
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  daysText: {
    color: "#3F3F42",
    fontSize: { xs: "3vw", lg: "1vw" },
    fontWeight: 600,
  },
  pricePerTicket: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "1em",
  },
  joinButtonText: {
    backgroundColor: theme.palette.primary.main,
    padding: "0.5em 1.4em",
    borderBottomRightRadius: "1em",
    borderTopLeftRadius: "1em",
    color: "white",
    fontSize: { xs: "3.1vw", lg: "1.1vw" },
    fontWeight: "500",
  },
};
