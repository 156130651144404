import { ethers } from "ethers";
import contractAbi from "../abi/swssDraw";
import { contractAddress } from "../config";
import { etherToWei } from "../utils/common";
import Cookies from "js-cookie";
import { blockchainNetworks } from "../config/blockchain";

/**
 * Connect to MetaMask wallet
 * @returns {Promise<Object>} - Resolve with a object containing status and address
 */
export const ConnectMetaMask = async () => {
  // Check if MetaMask is installed
  if (window.ethereum && window.ethereum.isMetaMask) {
    try {
      // Request MetaMask to connect to the account

      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      // Check if any account is found
      if (accounts.length === 0) {
        throw new Error("No MetaMask accounts found.");
      }
      // Return the first account found
      const address = accounts[0];
      return { status: "success", address };
    } catch (error) {
      // Return error if MetaMask connection is denied
      return { status: "error", error: "User denied MetaMask connection." };
    }
  } else {
    // Return error if MetaMask is not installed
    return { status: "error", error: "MetaMask is not installed!" };
  }
};

/**
 * Connect to Trust Wallet
 * @returns {Promise<Object>} - Resolve with a object containing status and address
 */
export const ConnectTrustWallet = async () => {
  if (window.ethereum && window.ethereum.isTrust) {
    try {
      // Request Trust Wallet to connect to the account
      await window.ethereum.request({ method: "eth_requestAccounts" });
      // Get the provider from Trust Wallet
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      // Get the signer from the provider
      const signer = provider.getSigner();
      // Get the address from the signer
      const address = await signer.getAddress();
      // console.log("Connected Trust Wallet Address: ", address);
      return { status: "success", address };
    } catch (error) {
      // Return error if Trust Wallet connection is denied
      return { status: "error", error: "User denied Trust Wallet connection." };
    }
  } else {
    // Return error if Trust Wallet is not installed or not detected
    return {
      status: "error",
      error: "Trust Wallet is not installed or not detected!",
    };
  }
};

/**
 * Connect to a wallet using a specified connector.
 * @param {Array} connectors - An array of wallet connectors.
 * @returns {Promise<Object>} - Resolves with an object containing the status and address or an error message.
 */
export const ConnectWithOther = async (connectors) => {
  try {
    // Use the first connector in the array
    const connector = connectors[0];

    // Attempt to connect using the connector
    const connectionResponse = await connector.connect();

    // Check if connection response contains account information
    if (
      connectionResponse &&
      connectionResponse.accounts &&
      connectionResponse.accounts.length > 0
    ) {
      const connectedAddress = connectionResponse.accounts[0];
      return { status: "success", address: connectedAddress };
    } else {
      return {
        status: "error",
        error: "Account information not found in the response.",
      };
    }
  } catch (error) {
    // Log and return error if connection fails
    console.error("Error connecting to wallet: ", error);
    return { status: "error", error: "Failed to connect to the wallet" };
  }
};

export const checkWalletLocked = async () => {
  if (
    window.ethereum?.isMetaMask &&
    Cookies.get("connectedWalletType") === "Metamask"
  ) {
    try {
      // Request the accounts from MetaMask
      const accounts = await window.ethereum.request({
        method: "eth_accounts",
      });

      // Check if accounts are available (if the array is not empty)
      if (accounts && accounts.length > 0) {
        return true;
      } else {
        const value = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        if (value && value.length > 0) {
          return true;
        } else {
          return false;
        }
      }
    } catch (error) {
      console.error("Error checking wallet status:", error);
      return false;
    }
  }
  return true;
};

/** ------------------------- Smart Contract Functions ----------------------------------------- */

/**
 * Checks the current allowance of a token and requests approval if the allowance is insufficient.
 * @param {string} tokenContractAddress - The address of the token contract.
 * @param {string} spenderAddress - The address of the spender.
 * @param {ethers.BigNumber} amountToSpend - The amount to be approved for spending.
 * @returns {Promise<boolean>} - Returns true if approval is successful or if allowance is sufficient, false otherwise.
 */
export const checkToken = async (tokenContractAddress, blockchain) => {
  try {
    const blockchainData = blockchainNetworks[blockchain];
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    // const provider = new ethers.providers.JsonRpcProvider(blockchainData.rpcUrl);

    const signer = provider.getSigner();

    // ERC-20 Token contract ABI to interact with allowance and approve functions
    const erc20Abi = [
      "function allowance(address owner, address spender) view returns (uint256)",
      "function approve(address spender, uint256 amount) public returns (bool)",
      "function balanceOf(address owner) public view returns (uint256)",
    ];

    const tokenContract = new ethers.Contract(
      tokenContractAddress,
      erc20Abi,
      signer
    );
    const connectedAddress = await signer.getAddress();

    const currentChainIdHex = await window.ethereum.request({
      method: "eth_chainId",
    });
    const currentChainId = parseInt(currentChainIdHex, 16);

    if (parseInt(currentChainId) !== parseInt(blockchainData.chainId)) {
      return {
        status: "error",
        message: `Wrong network. Please connect to ${blockchainData.networkName}`,
      };
    }
    return {
      status: "success",
      message: "Ticket Checked successfully.",
      contractData: {
        connectedAddress,
        tokenContract,
      },
    };
  } catch (error) {
    console.error("Error during token approval:", error.message || error);
    return { status: "error", message: error.message };
  }
};

export const approveToken = async (
  amountToSpend,
  connectedAddress,
  tokenContract,
  tokenContractAddress
) => {
  try {
    // Check if the user has the token in their wallet
    let balance;
    try {
      balance = await tokenContract.balanceOf(connectedAddress);
    } catch (error) {
      throw new Error(
        `Token not supported on this chain. Verify your wallet's network connection.`
      );
    }
    if (balance.lte(0)) {
      throw new Error(
        `Token not found in wallet. Please ensure the token is imported: ${tokenContractAddress}`
      );
    }

    // Check current allowance
    const currentAllowance = await tokenContract.allowance(
      connectedAddress,
      contractAddress
    );
    // console.log(
    //   "Current Allowance:",
    //   ethers.utils.formatUnits(currentAllowance, 18)
    // );

    // If allowance is less than the required amount, request approval
    if (currentAllowance.lt(amountToSpend)) {
      // console.log("Allowance insufficient, requesting approval...");

      // Request approval
      const approvalTx = await tokenContract.approve(
        contractAddress,
        amountToSpend
      );
      await approvalTx.wait(); // Wait for the transaction to be mined

      // console.log("Approval successful:", approvalTx.hash);
      return { status: "success", message: "Ticket Approved successfully." };
    } else {
      // console.log("Allowance is sufficient");
      return { status: "success", message: "Ticket Approved successfully." };
    }
  } catch (error) {
    console.error("Error during token approval:", error.message || error);
    return { status: "error", message: error.message };
  }
};

/**
 * Handles the purchase of a draw ticket.
 * @param {Object} data - The data needed for the ticket purchase.
 * @param {string} connectedAddress - The address of the connected wallet.
 * @returns {Promise<Object>} - Returns an object with the status and message of the purchase.
 */
export const purchaseDrawTicket = async (data, connectedAddress) => {
  if (!data || !connectedAddress) {
    return {
      status: "error",
      message: "Invalid data or connected wallet address.",
    };
  }

  try {
    const blockchainData = blockchainNetworks[data.blockchain];
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const currentChainIdHex = await window.ethereum.request({
      method: "eth_chainId",
    });
    const currentChainId = parseInt(currentChainIdHex, 16);

    if (parseInt(currentChainId) !== parseInt(blockchainData.chainId)) {
      return {
        status: "error",
        message: `Wrong network. Please connect to ${blockchainData.networkName}.`,
      };
    }

    // Create a contract instance
    const tokenContract = new ethers.Contract(
      contractAddress,
      contractAbi,
      signer
    );

    // Calculate the total ticket value in Wei
    const totalTicketValueInWei = data.ticketPrice * data.ticketCount;

    const txOptions = { value: etherToWei(totalTicketValueInWei.toString()) };
    let tx; // Declare transaction variable

    // Check if the ticket purchase requires token approval
    if (
      data.tokenContractAddress !== "0x0000000000000000000000000000000000000000"
    ) {
      tx = await tokenContract.participateInDraw(
        data.drawId.toString(),
        data.referrer.toString(),
        data.ticketCount.toString()
      );
    } else {
      tx = await tokenContract.participateInDraw(
        data.drawId.toString(),
        data.referrer.toString(),
        data.ticketCount.toString(),
        txOptions
      );
    }
    // await tx.wait();

    // Wait for the transaction to be mined and get the receipt
    const receipt = await tx.wait();

    return { status: "success", message: "Ticket purchased successfully.", purchaseData: { txHash: receipt.transactionHash, fromAddress: receipt.from } };
  } catch (error) {
    console.error("Transaction failed:", error.message || error);
    return {
      status: "error",
      message: `${error.message || "Unknown error occurred"}`,
    };
  }
};
