import dayjs from "dayjs";
import { DateTime } from "luxon";

export const iSOToDateTime = (isoDate) => {
  try {
    const formattedDate = dayjs(isoDate).format("DD MMM YYYY, h:mm:ss A");
    return formattedDate;
  } catch (error) {
    return "-";
  }
};

export const formatISODate = (isoDate) => {
  const dateTime = DateTime.fromISO(isoDate);
  return dateTime.toFormat("dd LLL yyyy, h:mm:ss a");
};

export const formatISOsmDate = (isoDate) => {
  const dateTime = DateTime.fromISO(isoDate);
  return dateTime.toFormat("dd LLL yyyy, h:mm a");
};
