import {
  Box,
  Button,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { styles } from "../styles";
import Celebration from "../../../assets/svg/Celebration";
import { useEffect, useState } from "react";
import { winnersData } from "../../../api/ApiService";
import { formatISODate } from "../../../utils/dateUtils";
import { Link, useNavigate } from "react-router-dom";

const RecentWinners = () => {
  const [topWinners, setTopWinners] = useState([]);
  const navigate = useNavigate();

  const getWinnersData = async () => {
    try {
      const response = await winnersData();

      if (response && response?.data?.length > 0) {
        const sortedData = response.data
          .sort(
            (a, b) =>
              parseFloat(b?.prizeAmount?.["$numberDecimal"]) -
              parseFloat(a?.prizeAmount?.["$numberDecimal"])
          )
          .slice(0, 10);
        setTopWinners(sortedData);
      }
    } catch (error) {
      console.error("Error fetching winners:", error);
    }
  };

  useEffect(() => {
    getWinnersData();
  }, []);

  return (
    <Box>
      <section style={{ position: "relative" }}>
        <Box sx={styles.celebrationImage1}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationImage2}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationImage3}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationImage4}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationImage5}>
          <Celebration />
        </Box>
        <Box sx={styles.celebrationBg}>
          <Typography sx={styles.titleText}>
            RECENT <span style={styles.titleHighLight}>WINNERS</span>
          </Typography>
          <Typography
            sx={{
              ...styles.faqBoxTitle,
              textAlign: "center",
              paddingTop: "15px",
            }}
          >
            Winners are on their way! Stay tuned as we prepare for our first
            exciting draw – the future of SwssDraw is just beginning!
          </Typography>
          <Container>
            <TableContainer component={Paper} sx={styles.tableContainerStyle}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={styles.tableHeadCell}>#</TableCell>
                    <TableCell sx={styles.tableHeadCell}>Draw ID</TableCell>
                    <TableCell sx={styles.tableHeadCell}>Draw Name</TableCell>
                    <TableCell sx={styles.tableHeadCell}>
                      Ticket Number
                    </TableCell>
                    <TableCell sx={styles.tableHeadCell}>Level</TableCell>
                    <TableCell sx={styles.tableHeadCell}>Win Prize</TableCell>
                    <TableCell sx={styles.tableHeadCell}>Date/Time</TableCell>
                    <TableCell sx={styles.tableHeadCell}>
                      Winner Address
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topWinners.map((item, index) => (
                    <TableRow
                      sx={{ backgroundColor: "rgba(242, 242, 242, 1)" }}
                      key={item._id}
                    >
                      <TableCell sx={styles.tableBodyCell}>
                        {index + 1}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {item.drawReference}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        <Link
                          to={`/draw/${item.drawReference}`}
                          style={styles.linkStyle}
                        >
                          <Typography sx={styles.linkStyle}>
                            {item.drawName}
                          </Typography>
                        </Link>
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {item.ticketNumber}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {item.level}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {`USDT ${parseFloat(
                          item.prizeAmount?.["$numberDecimal"]
                        )}`}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {formatISODate(item.createdAt)}
                      </TableCell>
                      <TableCell sx={styles.tableBodyCell}>
                        {item.winnerAddress}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Stack
              direction="row"
              justifyContent={"flex-end"}
              alignItems="center"
              spacing={0.5}
            >
              <Button
                variant="text"
                onClick={() => {
                  navigate("/winners");
                }}
                sx={styles.alightRight}
              >
                <Typography sx={styles.viewMoreButtonText}>View All</Typography>
              </Button>
            </Stack>
          </Container>
        </Box>
      </section>
    </Box>
  );
};
export default RecentWinners;
