export const CustomerData = [
  {
    name: "Michael R.",
    title: "Early Supporter",
    comment:
      "SwssDraw’s decentralized, blockchain-based draw system is a game changer. The transparency and fairness it brings to the table are unmatched, offering a level of trust and security that traditional draws simply can’t provide.",
  },
  {
    name: "Sophie L.",
    title: "Blockchain Enthusiast",
    comment:
      "The concept of using blockchain and smart contracts for draws is revolutionary. I love the idea of being able to see every step of the process and knowing the outcomes are completely secure and fair—this is exactly what the industry needed.",
  },
  {
    name: "James C.",
    title: "Blockchain Investor",
    comment:
      "As an investor, I’m thrilled by SwssDraw’s transparent and secure approach to draws. The fact that everything is handled by smart contracts gives me great confidence in the project, and I’m excited to see how it will evolve and change the draw landscape.",
  },
];
