import theme from "../../theme/Theme";

const commonStyle = {
  tableBackground: {
    mt: 4,
    borderRadius: "6px",
    overflowY: "hidden",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    outline: `0.8px solid ${theme.custom.tableBorder}`,
  },
  ticketTitleStyle: {
    color: "rgba(0, 0, 0, 1)",
    fontSize: "0.75rem",
    fontWeight: 600,
    textAlign: "center",
    textTransform: "uppercase",
  },
  ticketValueTitleStyle: {
    color: "#525252",
    fontSize: "0.8rem",
    fontWeight: 500,
    textAlign: "center",
    padding: "0.5em 0",
    textDecoration: "none",
    border: "none",
  },
  noTransactions: {
    color: "#525252",
    fontSize: "1rem",
    fontWeight: 500,
    textAlign: "center",
    padding: "2em 0",
  },
  transactionLinkStyle: {
    color: theme.custom.link,
    fontSize: "0.7rem",
    fontWeight: 600,
    textAlign: "center",
    padding: "0.5em 0",
    textDecoration: "none",
  },
  selectMenuText: {
    color: theme.palette.text.primary,
    fontSize: "0.8rem",
    fontWeight: 600,
  },
  selectLabel: {
    color: theme.palette.primary.main,
    fontSize: "1rem",
    fontWeight: 600,
  },
};
export default commonStyle;
