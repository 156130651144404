import {
  Box,
  Button,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styles } from "../styles";
import Slider from "react-slick";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../customdot.css";
import { CarouselData } from "../data/CarouselData";
import { useNavigate } from "react-router-dom";
import { network } from "../../../config";
import { useEffect, useState } from "react";
import { formatNumber } from "../../../utils/common";
import { formatISOsmDate } from "../../../utils/dateUtils";
import { HiOutlineTicket } from "react-icons/hi2";
import theme from "../../../theme/Theme";
import { TicketArrow } from "../../../assets";

const HomeCarousel = () => {
  const navigate = useNavigate();
  const [promotionData, setPromotionData] = useState([]);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [levelsPrize, setLevelPrize] = useState([]);
  const NextArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow next" onClick={onClick}>
        <ChevronRightIcon width={isMobile ? 12 : 22} />
      </div>
    );
  };

  const PrevArrow = ({ onClick }) => {
    return (
      <div className="custom-arrow prev" onClick={onClick}>
        <ChevronLeftIcon width={isMobile ? 12 : 22} />
      </div>
    );
  };

  const sliderSettings = {
    dots: true,
    dotsClass: "slick-dots custom-dots", // Use a custom class for the dots
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrow: true,
    autoplaySpeed: 2000,
    cssEase: "linear",
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const handleParticipateClick = () => {
    navigate("/draw");
  };

  const getRewardPrize = async (drawId) => {
    const drawDataModule = await import(
      `../../../data/${network}/draw-details/draw-${drawId}`
    );
    const drawRewards = {
      drawId: drawId,
      level1: drawDataModule.drawDetails.rewardsTable[0].rewardPrize,
      level2: drawDataModule.drawDetails.rewardsTable[1].rewardPrize,
      level3: drawDataModule.drawDetails.rewardsTable[2].rewardPrize,
    };
    setLevelPrize(drawRewards);
  };

  const getDrawsListData = async () => {
    setPromotionData([]);
    const data = await import(`../../../data/${network}/DrawsList`);

    const promotingData = data.DrawsList.filter(
      (item) => item.promoting === true
    );

    promotingData.forEach((element) => {
      getRewardPrize(element.id);
    });

    setPromotionData(promotingData);
  };

  useEffect(() => {
    getDrawsListData();
  }, []);

  const handleDrawClick = (drawId) => {
    navigate(`/draw/${drawId}`);
  };

  const SliderComponent = ({ item, index }) => {
    return (
      <Box sx={styles.carouselWithoutCharacterBg} key={index}>
        <Box sx={styles.flexContainer}>
          <Box sx={{ flex: 2 }}>
            <Box sx={styles.carouselDescription}>
              <Typography
                sx={styles.heading1}
              >{`${item.title} Awaits!`}</Typography>
              <Typography
                sx={styles.heading2}
              >{`Winning Prize pool of`}</Typography>
              <Typography sx={styles.heading3}>
                {`USDT ${formatNumber(item.totalPrice)}`}
              </Typography>
              <Typography
                sx={{
                  ...styles.description,
                  marginTop: "0px",
                  fontWeight: 600,
                  lineHeight: 1.5,
                  maxWidth: "100%",
                }}
              >
                {`Tickets starting at just USDT ${item.pricePerTicket}!`}
              </Typography>

              {item.promotionLine ? (
                <Typography
                  sx={{
                    ...styles.description,
                    marginTop: "0px",
                    fontWeight: 600,
                    lineHeight: 1.5,
                    minHeight: "2.2em",
                    height: "2.2em",
                    maxWidth: "100%",
                  }}
                >
                  {item.promotionLine}
                </Typography>
              ) : (
                <Box sx={{ height: "2.2em" }}></Box>
              )}
            </Box>
            <Box
              sx={styles.buttonWrapper}
              onClick={() => {
                handleDrawClick(item.id);
              }}
            >
              <Box sx={styles.buttonContainer}>
                <Typography sx={styles.buttonText}>Participate NOW</Typography>
              </Box>
            </Box>
          </Box>
          <Box sx={styles.carouselDrawCard}>
            <Stack
              direction={"column"}
              alignItems={"flex-start"}
              sx={{ padding: "0.5em" }}
              gap={{ xs: 1, md: 1 }}
            >
              <Stack direction={"row"} alignItems={"center"}>
                <Typography sx={styles.carouselTextStyle} mr={1}>
                  {`${formatISOsmDate(item.startISODate)}`}
                </Typography>
                <Box
                  component={"img"}
                  src={TicketArrow}
                  sx={{ width: "3em" }}
                />
                <Typography sx={styles.carouselTextStyle} ml={1}>
                  {`${formatISOsmDate(item.endISODate)}`}
                </Typography>
              </Stack>
              <Divider flexItem sx={{ borderColor: theme.custom.white }} />

              <Box
                component={"img"}
                src={item.bannerImage}
                sx={styles.carouselDrawCardImage}
              />
              <Stack
                direction={"row"}
                alignItems={"center"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
                gap={1}
                mt={2}
              >
                <Stack direction={"column"} sx={{ flex: 1 }}>
                  <Typography sx={styles.carouselWinProbTextStyle}>
                    1st Winner
                  </Typography>
                  <Typography sx={styles.carouselTotalProbValueStyle}>
                    {`USDT ${formatNumber(item.firstWinner)}`}
                  </Typography>
                </Stack>
                <Stack direction={"column"} sx={{ flex: 1 }}>
                  <Typography sx={styles.carouselWinProbTextStyle}>
                    Total Winners
                  </Typography>
                  <Typography sx={styles.carouselTotalProbValueStyle}>
                    {item.totalWinners}
                  </Typography>
                </Stack>
                <Stack direction={"column"} sx={{ flex: 1 }}>
                  <Typography sx={styles.carouselWinProbTextStyle}>
                    Win Probability
                  </Typography>
                  <Typography sx={styles.carouselTotalWinProbValueStyle}>
                    {item.winingProbability}
                  </Typography>
                </Stack>
              </Stack>

              <Stack
                direction={"row"}
                alignItems={"flex-end"}
                justifyContent={"space-between"}
                sx={{ width: "100%" }}
                gap={1}
                mt={2}
              >
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  <HiOutlineTicket size={40} color={theme.custom.white} />
                  <Box>
                    <Typography
                      sx={{
                        ...styles.carouselWinProbTextStyle,
                        textAlign: "left",
                      }}
                    >
                      Per Ticket
                    </Typography>
                    <Typography
                      sx={styles.carouselWinProbValueStyle}
                    >{`USDT ${formatNumber(item.pricePerTicket)}`}</Typography>
                  </Box>
                </Stack>

                <Button
                  variant="contained"
                  sx={styles.buyNowButton}
                  onClick={() => {
                    handleDrawClick(item.id);
                  }}
                >
                  Participate
                </Button>
              </Stack>
            </Stack>
          </Box>
        </Box>
      </Box>
    );
  };

  return (
    <section style={styles.carouselStyle}>
      {promotionData && promotionData.length > 0 ? (
        promotionData.length === 1 ? (
          <SliderComponent item={promotionData[0]} />
        ) : (
          <Slider {...sliderSettings}>
            {promotionData.map((item, index) => (
              <SliderComponent item={item} index={index} />
            ))}
          </Slider>
        )
      ) : (
        <Box sx={styles.carouselBg}>
          <Box sx={styles.carouselDescription}>
            <Typography sx={styles.heading1}>
              {CarouselData[0].title1}
            </Typography>
            <Typography sx={styles.heading2}>
              {CarouselData[0].title2}
            </Typography>
            <Typography sx={styles.heading3}>
              {CarouselData[0].title3}
            </Typography>
            <Typography sx={styles.description}>
              {CarouselData[0].description}
            </Typography>
          </Box>
          <Box sx={styles.buttonWrapper} onClick={handleParticipateClick}>
            <Box sx={styles.buttonContainer}>
              <Typography sx={styles.buttonText}>Participate NOW</Typography>
            </Box>
          </Box>
        </Box>
      )}
    </section>
  );
};

export default HomeCarousel;
