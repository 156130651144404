import {
  Box,
  Grid2,
  Pagination,
  PaginationItem,
  Typography,
} from "@mui/material";
import { styles } from "./styles";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import theme from "../../theme/Theme";
import DrawTicketComponent from "../../component/DrawTicketComponent";
import { network } from "../../config";

const DrawPage = () => {
  const navigate = useNavigate();
  const [drawsData, setDrawsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const itemsPerPage = 15;

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getDrawsListData = async () => {
    const data = await import(`../../data/${network}/DrawsList`);

    const paginatedItems = data.DrawsList.filter(
      (draw) => new Date(draw.endISODate) > new Date()
    )
      .slice(startIndex, endIndex)
      .sort((a, b) => new Date(a.endISODate) - new Date(b.endISODate));

    const ExpiredData = data.DrawsList.filter(
      (draw) => new Date(draw.endISODate) < new Date()
    )
      .slice(startIndex, endIndex)
      .sort((a, b) => new Date(a.endISODate) - new Date(b.endISODate));

    const completeData = paginatedItems.concat(ExpiredData);
    const totalPage = Math.ceil(completeData.length / itemsPerPage);
    setTotalPages(totalPage);
    setDrawsData(completeData);
  };

  useEffect(() => {
    getDrawsListData();
  }, []);

  return (
    <Box sx={styles.pageContainer}>
      <Box sx={styles.bannerImage}>
        <Box
          px={{ xs: 1, sm: 1, md: 4, lg: 4 }}
          py={{ xs: 1, sm: 1, md: 2, lg: 6 }}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
          }}
        >
          <Typography sx={styles.bannerTitle}>Contest List</Typography>
          <Typography sx={styles.bannerHeading}>
            Your Ticket To A Life-Changing Win!
          </Typography>
        </Box>
      </Box>
      <Box sx={styles.pageBackground} />
      <Box sx={styles.pageContent}>
        <Box sx={styles.filterRow}>
          <Typography sx={styles.paginationCount}>
            {`Showing ${currentPage}-${
              drawsData.length < itemsPerPage
                ? drawsData.length
                : currentPage * itemsPerPage
            } of ${drawsData.length} RESULT`}
          </Typography>
        </Box>
        <Grid2 container spacing={{ xs: 3, sm: 3, md: 3, lg: 2 }}>
          {drawsData.map((item, index) => (
            <Grid2
              item
              size={{ xs: 12, sm: 6, md: 4, lg: 4, xl: 4 }}
              key={index}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => {
                navigate(`/draw/${item.id}`);
              }}
            >
              <DrawTicketComponent
                drawData={item}
                soldTickets={item.soldTickets}
              />
            </Grid2>
          ))}
        </Grid2>
        <Box sx={styles.paginationContainer}>
          <Pagination
            count={totalPages}
            shape="rounded"
            size="large"
            sx={styles.pagination}
            onChange={handlePageChange}
            renderItem={(item) => (
              <PaginationItem
                {...item}
                sx={{
                  backgroundColor: item.selected
                    ? theme.custom.white
                    : theme.palette.primary.main,
                  borderColor: item.selected
                    ? theme.palette.primary.main
                    : theme.custom.white,
                  borderWidth: item.selected ? "2px" : "1px",
                  borderRadius: "12px",
                  color: item.selected
                    ? theme.palette.primary.main
                    : theme.custom.white,
                  "&:hover": {
                    backgroundColor: theme.palette.primary.main,
                  },
                  ...(item.type === "previous" || item.type === "next"
                    ? {
                        backgroundColor: "transparent",
                        color: theme.palette.primary.main,
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: theme.palette.primary.main,
                          color: theme.custom.white,
                        },
                      }
                    : {}),
                }}
              />
            )}
          />
        </Box>
      </Box>
    </Box>
  );
};
export default DrawPage;
