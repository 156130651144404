import React, { useState, useEffect } from "react";
import { fetchUserTickets } from "../../../api/transactionsService";
import Cookies from "js-cookie"; // Assuming the connected wallet is stored in cookies
import {
  Box,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import DashboardPageTitle from "../../../component/DashboardPageTitle";
import DashboardLoader from "../../../component/DashboardLoader";
import { trimHash } from "../../../utils/common";
import { iSOToDateTime } from "../../../utils/dateUtils";
import { blockchainNetworks } from "../../../config/blockchain";
import theme from "../../../theme/Theme";
import commonStyle from "../common.style";
import { network } from "../../../config";
import { fetchTransactionData } from "../../../api/ApiService";
import { Link } from "react-router-dom";

const MyTransactionsPage = () => {
  const [transactionsList, setTransactionsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const getTransactionsData = async () => {
    setLoading(true);
    const response = await fetchTransactionData();
    if (response?.data && response?.data.length > 0) {
      const updatedData = await addTransactionUrls(response?.data);
      setTransactionsList(updatedData);
    }
    setLoading(false);
  };

  const addTransactionUrls = async (data) => {
    const updatedData = await Promise.all(
      data.map(async (item) => {
        const transactionUrl = await getTransactionUrl(
          item.drawId,
          item.transactionHash
        );
        return { ...item, transactionUrl };
      })
    );

    return updatedData;
  };

  useEffect(() => {
    getTransactionsData();
  }, []);

  const getTransactionUrl = async (drawId, transactionHash) => {
    const data = await import(`../../../data/${network}/DrawsList`);
    const list = data?.DrawsList?.find((draw) => draw.id === drawId);
    return `${
      blockchainNetworks[list?.blockchain]?.explorerBaseUrl
    }/tx/${transactionHash}`;
  };

  return (
    <Box p={{ xs: 1, md: 3, lg: 3 }}>
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <DashboardPageTitle title={"My Tickets Transactions"} />
      </Stack>
      {loading ? (
        <DashboardLoader isLoading={loading} />
      ) : (
        <TableContainer component={Paper} sx={commonStyle.tableBackground}>
          <Table size="small">
            <TableHead>
              <TableRow sx={{ backgroundColor: theme.custom.tableHeaderBg }}>
                <TableCell sx={commonStyle.ticketTitleStyle}>#</TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>Draw ID</TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Draw Name
                </TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Ticket Count
                </TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Total Amount
                </TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Transaction Date
                </TableCell>
                <TableCell sx={commonStyle.ticketTitleStyle}>
                  Transaction Hash
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {transactionsList?.length > 0 ? (
                transactionsList.map((ticket, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor:
                        index % 2 !== 0 ? "#f3f3f3" : "transparent",
                    }}
                  >
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {index + 1}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {ticket.drawId}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      <Link
                        to={`/draw/${ticket.drawId}`}
                        style={commonStyle.transactionLinkStyle}
                      >
                        {ticket.drawName}
                      </Link>
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {ticket.ticketCount}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {`USDT ${parseFloat(ticket?.totalAmount)}`}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      {iSOToDateTime(ticket.purchaseDate)}
                    </TableCell>
                    <TableCell sx={commonStyle.ticketValueTitleStyle}>
                      <a
                        href={ticket.transactionUrl}
                        style={commonStyle.transactionLinkStyle}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {trimHash(ticket.transactionHash)}
                      </a>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={8} sx={{ textAlign: "center" }}>
                    <Typography sx={commonStyle.noTransactions}>
                      No Transactions Found
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Box>
  );
};

export default MyTransactionsPage;
