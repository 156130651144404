import { Box, Card, CardContent, Stack, Typography } from "@mui/material";
import { styles } from "../styles";
import { CustomerData } from "../data/CustomerData";
import { useState } from "react";
import theme from "../../../theme/Theme";

const HappyCustomer = () => {
  const [activeCustomer, setActiveCustomer] = useState(1);
  return (
    <Box sx={customerStyle.container}>
      <Box sx={{ margin: "2em 0 1em 3em" }}>
        <Typography sx={styles.happyCustomerTitle}>
          Happy <span style={styles.happyCustomerHighLight}>Customers</span>
        </Typography>
        <Typography sx={styles.happyCustomerSubTitle}>
          See what our users have to say about their exciting experiences with
          SwssDraw!
        </Typography>
      </Box>

      <Box sx={styles.customerCardHolder}>
        {CustomerData.map((customer, index) => (
          <Card
            key={index}
            data-aos="flip-up"
            sx={{
              ...styles.happyCustomerCard,
              "&:hover": {
                transform: {
                  xs: "scale(1)",
                  sm: "scale(1)",
                  md: activeCustomer !== index ? "scale(1.3)" : "scale(1.3)",
                  lg: activeCustomer !== index ? "scale(1.3)" : "scale(1.3)",
                },
              },
              transform: {
                xs: "scale(1)",
                sm: "scale(1)",
                md: activeCustomer === index ? "scale(1.3)" : "scale(1)",
                lg: activeCustomer === index ? "scale(1.3)" : "scale(1)",
              },
            }}
          >
            <CardContent sx={{ p: 4 }}>
              <Typography sx={styles.customerName}>{customer.name}</Typography>
              <Typography sx={styles.customerDesignation}>
                {customer.title}
              </Typography>
              <Typography sx={styles.customerDescription}>
                {customer.comment}
              </Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        alignItems={"center"}
        spacing={1}
        mt={3}
        mx={1}
        mr={10}
        pb={10}
      >
        {/* <Button
           variant="contained"
           sx={styles.viewMoreButton}
           onClick={handleTestimonialViewMoreClicked}
         >
           View More
         </Button> */}
      </Stack>
    </Box>
  );
};
export default HappyCustomer;
const customerStyle = {
  container: {
    background: `linear-gradient(325deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.dark} 87%, ${theme.custom.primaryDarker} 100%)`,
    py: 4,
    top: "-4.4em",
    position: "relative",
  },
};
