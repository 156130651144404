import Web3 from "web3";
import Cookies from "js-cookie";
import swssDrawABI from "../abi/swssDraw";
import { contractAddress, fromBlock, network } from "../config";

import { blockchainNetworks } from "../config/blockchain";
import { drawTicketApi } from "./ApiService";

let web3, kittyDrawContract, lastUsedBlockchain;

async function initializeProvider(networkKey, infuraUrl) {
  if (lastUsedBlockchain !== networkKey) {
    web3 = new Web3(new Web3.providers.HttpProvider(infuraUrl));
    kittyDrawContract = new web3.eth.Contract(swssDrawABI, contractAddress);
    lastUsedBlockchain = networkKey;
  }
}

export const fetchUserTickets = async () => {
  try {
    const address = Cookies.get("connectedAddress");
    if (!address) {
      throw new Error("User address not found in cookies.");
    }

    const drawDataList = [];
    const data = await import(`../data/${network}/DrawsList`);
    for (const drawJsonData of data.DrawsList) {
      const { blockchain } = drawJsonData;

      if (
        !blockchain ||
        (blockchain !== "bscMainnet" && blockchain !== "bscTestnet")
      ) {
        continue;
      }
      const blockchainData = blockchainNetworks[blockchain];
      await initializeProvider(blockchain, blockchainData.infuraUrl);

      const apiResponse = await drawTicketApi(address);

      if (apiResponse.status !== "success") {
        apiResponse?.data?.map((draw) => {
          const ticketId = draw?.drawId;
          const drawData = {
            id: ticketId,
            name: draw?.drawName ?? "",
            drawId: ticketId,
            ticketNumber: "",
            startDate: draw?.startTime ?? "",
            endDate: draw?.endTime ?? "",
            poolPrize: drawJsonData?.totalPrice ?? "0",
            winProbability: drawJsonData?.winingProbability ?? "",
            ticketData: [],
            currencyTicker: draw?.currencyTicker ?? "",
          };
          draw?.tickets?.forEach((ticket) => {
            drawData.ticketData.push({
              drawId: ticketId,
              ticketNumber: ticket.ticketNumber,
              NFTNumber: ticket.nftTokenId,
              tokenURI: `${blockchainData.explorerBaseUrl}/nft/${contractAddress}/${ticket.nftTokenId}`,
              purchaseDate: ticket.purchaseDate,
              ticketPrice: `${web3.utils.fromWei(ticket.ticketPrice, "ether")}`,
              currencyTicker: drawJsonData?.currencyTicker || "",
              nftExplorerUrl: `${blockchainData.explorerBaseUrl}/nft/${contractAddress}/${ticket.nftTokenId}`,
              transactionHashUrl: `${blockchainData.explorerBaseUrl}/tx/${ticket.transactionHash}`,
              transactionHash: ticket.transactionHash || "",
            });
          });

          drawDataList.push(drawData);
        });
        return drawDataList;
      }
      return drawDataList;
    }
  } catch (error) {
    console.error("Error fetching user tickets:", error);
    return [];
  }
};
